import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import Button from "components/Button";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/legacy/build/pdf.worker.min.mjs",
//   import.meta.url,
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface PDFViewerProps {
  pdfUrl: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1.0);

  const onDocumentLoadSuccess = ({ numPages: count }: { numPages: number }) => {
    setNumPages(count);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const onZoom = () => {
    if (zoom === 1.0) setZoom(1.5);
    else if (zoom === 1.5) setZoom(2.0);
    else if (zoom === 2.0) setZoom(3.0);
    else setZoom(1.0);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <Button onClick={previousPage} disabled={pageNumber <= 1}>
          Previous
        </Button>
        <Button onClick={nextPage} disabled={pageNumber >= (numPages ?? 0)}>
          Next
        </Button>
        <Button onClick={onZoom}>Zoom</Button>
      </div>
      <div className="h-[540px] w-[360px] overflow-auto shadow-md">
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={360} scale={zoom} />
        </Document>
      </div>
    </div>
  );
};

export default PDFViewer;
