import { RouterProvider } from "react-router-dom";

// import LoadingScreen from "components/LoadingScreen";
import RootToastProvider from "context/RootToastProvider";
import { useInit } from "hooks/useInit";
import router from "router";
import { StoreProvider } from "stores";

function App() {
  const { store, ready } = useInit();

  if (!ready || !store) {
    // return <LoadingScreen />;
    return null;
  }

  return (
    <RootToastProvider>
      <StoreProvider value={store}>
        <RouterProvider router={router} />
      </StoreProvider>
    </RootToastProvider>
  );
}

export default App;
