import { z } from "zod";

export const CertificateType = {
  MineStockpile: 0,
  MineTruck: 1,
  PortStockpile: 2,
  PortTruck: 3,
  Vessel: 4,
} as const;

export const CERTTYPE_CLASS = {
  0: "bg-badge-mineStockpile",
  1: "bg-badge-mineTruck",
  2: "bg-badge-portStockpile",
  3: "bg-badge-portTruck",
  4: "bg-badge-vessel",
} as const;

export const CERTTYPE_LABEL = {
  0: "Mine Stockpile",
  1: "Mine Truck",
  2: "Port Stockpile",
  3: "Port Truck",
  4: "Vessel",
} as const;

export const CertificateFilterSchema = z
  .object({
    search: z.string(),
    certType: z.coerce.number(),
    supplier: z.coerce.number(),
    createdBy: z.coerce.number(),
    loadedAt: z
      .object({
        lte: z.string(),
        gte: z.string(),
      })
      .partial(),
    createdAt: z
      .object({
        lte: z.string(),
        gte: z.string(),
      })
      .partial(),
    page: z.coerce.number(),
    _limit: z.coerce.number(),
    _all: z.string().transform((value) => value === "true"),
  })
  .partial();

export type CertificateFilter = z.infer<typeof CertificateFilterSchema>;
