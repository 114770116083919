import { observer } from "mobx-react-lite";
import {
  FC,
  useCallback,
  useState,
  useEffect,
  useMemo,
  ChangeEvent,
} from "react";
import { useDropzone } from "react-dropzone";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "components/Button";
import { InputGroup, Label, Select } from "components/FormElements";
import Modal from "components/Modal";
import { useToast } from "components/toast/context";
import Supplier from "models/Supplier";
import { useStore } from "stores";

import SelectedFile from "./SelectedFile";

type FileListProps = {
  files: File[];
  handleDelete: (index: number) => void;
  onErrorChange: (hasError: boolean) => void;
};

const FileList: FC<FileListProps> = ({
  files,
  handleDelete,
  onErrorChange,
}) => {
  const { authStore, certificateStore } = useStore();
  const [fileNameAPIExists, setFileNameAPIExists] = useState<boolean[]>([]);
  const fileNameCount: Record<string, number> = files.reduce(
    (acc: Record<string, number>, file) => {
      acc[file.name] = (acc[file.name] || 0) + 1;
      return acc;
    },
    {},
  );
  const checkNameDuplicate = useCallback(async () => {
    const res = await Promise.all(
      files.map(async (file): Promise<boolean> => {
        const response = await certificateStore.checkNameDuplicate(
          authStore.authHeader,
          file.name,
        );

        return response;
      }),
    );

    setFileNameAPIExists(res);
  }, [files, authStore, certificateStore]);

  useEffect(() => {
    checkNameDuplicate();
  }, [checkNameDuplicate]);

  console.log("fileNameCount", fileNameCount);

  useEffect(() => {
    const hasError = Object.values(fileNameCount).some((count) => count > 1);
    const hasAPIDuplicate = fileNameAPIExists.filter((bool) => bool).length > 0;

    onErrorChange(hasError || hasAPIDuplicate);
  }, [fileNameCount, onErrorChange]);

  return (
    <div className="mt-2 flex flex-col gap-2">
      {files.map((file, index) => (
        <SelectedFile
          key={index}
          file={file}
          hasError={fileNameCount[file.name] > 1}
          hasAPIError={fileNameAPIExists[index]}
          onDelete={() => handleDelete(index)}
        />
      ))}
    </div>
  );
};

type AddCertificateModalProps = {
  open: boolean;
  onClose?: () => void;
  onSubmitUpdate?: (ids: number[]) => void;
};

type AddCertificateFormInputs = {
  certType: string;
  grade: string | null;
  supplier: string | null;
  files: File[];
};

const AddCertificateModal: FC<AddCertificateModalProps> = ({
  open = false,
  onClose = () => {},
  onSubmitUpdate = () => {},
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    // formState: { errors },
  } = useForm<AddCertificateFormInputs>({
    defaultValues: {
      certType: "",
      grade: null,
      supplier: null,
      files: [],
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [supplierList, setSupplierList] = useState<Supplier[]>([]);
  const [hasFileError, setHasFileError] = useState(false);
  const [hasSuppliers, setHasSuppliers] = useState<boolean>(true);
  const {
    authStore,
    certTypeStore,
    certificateStore,
    gradeStore,
    supplierStore,
  } = useStore();
  const { showToastVariant } = useToast();
  const certType = watch("certType");
  const grade = watch("grade");
  const supplier = watch("supplier");

  useEffect(() => {
    if (certType) {
      const certTypeInt = parseInt(certType, 10);
      const certTypeObj = certTypeStore.types.get(certTypeInt);

      if (certTypeObj) setHasSuppliers(certTypeObj?.hasSupplier);
    }
    setValue("supplier", null);
  }, [certType, certTypeStore.typeList]);

  const fetchSuppliers = useCallback(async () => {
    if (grade) {
      const gradeId = parseInt(grade, 10);
      const { results: supplierData } = await supplierStore.fetchAllSuppliers(
        authStore.authHeader,
        gradeId,
      );

      setSupplierList(supplierData || []);
      setValue("supplier", null);
    }
  }, [grade]);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log("Dropped files:", acceptedFiles);
    setFiles((oldFiles) => [...oldFiles, ...acceptedFiles]);
    // setValue("file", acceptedFiles);
  }, []);

  useEffect(() => {
    if (files.length > 10) {
      showToastVariant({
        variant: "error",
        title: "Upload limit exceeded",
        subtitle: "Cannot upload more than 10 files",
      });

      setFiles((oldFiles) => oldFiles.slice(0, 10));
    }
  }, [files]);

  const handleClose = () => {
    setFiles([]);
    onClose();
  };

  const onSubmit: SubmitHandler<AddCertificateFormInputs> = async (data) => {
    setIsSubmitting(true);
    console.log("data submitted:", data);
    console.log("files: ", files);
    const response = await certificateStore.uploadCertificates(
      authStore.authHeader,
      {
        files,
        certType: data.certType,
        grade: data.grade,
        supplier: data.supplier,
      },
    );

    if (response.ok) {
      showToastVariant({
        variant: "success",
        title: "Success",
        subtitle: "Successfully uploaded certificates",
      });
      onSubmitUpdate(response.ids);
      handleClose();
    } else {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: response.details as string,
      });
    }
    setIsSubmitting(false);
  };

  const handleDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const isFormValid = useMemo(() => {
    if (!certType || !grade) {
      return false;
    }
    if (hasSuppliers && !supplier) {
      return false;
    }
    return true;
  }, [certType, grade, supplier, hasSuppliers]);

  const dragClassName = isDragActive ? " bg-gray-100" : "";

  return (
    <Modal
      title="Upload Certificate of Analysis"
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4">
          <InputGroup className="w-full">
            <Label className="text-base-main/70">Certificate Type</Label>
            <Select
              className="py-2"
              {...register("certType", {
                required: true,
              })}
              defaultValue=""
            >
              {certTypeStore.typeList.map((certType) => (
                <option key={certType.id} value={certType.id}>
                  {certType.name}
                </option>
              ))}
            </Select>
          </InputGroup>
          <InputGroup className="w-full">
            <Label className="text-base-main/70">Grade</Label>
            <Select
              className="py-2"
              {...register("grade", {
                required: true,
              })}
              defaultValue=""
            >
              <option value="" disabled />
              {gradeStore.gradeList.map((grade) => (
                <option key={grade.id} value={grade.id}>
                  {grade.name}
                </option>
              ))}
            </Select>
          </InputGroup>
          <InputGroup className="w-full">
            {hasSuppliers && (
              <>
                <Label className="text-base-main/70">Supplier</Label>
                <Select
                  className="py-2"
                  {...register("supplier", {
                    required: true,
                  })}
                  defaultValue=""
                >
                  <option value="" disabled />
                  {supplierList.map((supplier) => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))}
                </Select>
              </>
            )}
          </InputGroup>
          <div
            className={`flex h-full w-full flex-1 flex-col items-center justify-center 
          rounded border border-gray-400 hover:cursor-pointer  ${dragClassName}`}
            {...getRootProps()}
          >
            <div className="flex flex-col items-center justify-center gap-4 py-24">
              <p className=" text-gray-500">Drag and drop document here</p>
              <p className=" text-gray-500">or</p>
              <Button variant="outlined">Browse Files</Button>
              <input
                // {...field}
                // value={value?.fileName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  console.log("pdf file:", event.target.files);
                  if (event.target.files) {
                    const newFiles = Array.from(event.target.files);

                    setFiles((oldFiles) => [...oldFiles, ...newFiles]);
                  }
                }}
                type="file"
                multiple
                id="file"
                hidden
                {...getInputProps()}
              />
            </div>
          </div>

          {files.length > 0 && (
            <FileList
              files={files}
              handleDelete={handleDelete}
              onErrorChange={setHasFileError}
            />
          )}

          <div className="flex justify-end gap-2 border-t border-gray-200">
            <Button
              fullWidth
              onClick={onClose}
              disabled={isSubmitting}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              disabled={hasFileError || !isFormValid}
              isLoading={isSubmitting}
            >
              Analyze
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default observer(AddCertificateModal);
