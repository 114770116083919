import { useCallback, useState } from "react";

import Toast from "components/toast/Toast";
import ToastContents, {
  TOAST_BACKGROUND_COLORS,
} from "components/toast/ToastVariants";
import {
  ToastContextProvider,
  ToastProps,
  VariantProps,
  useToast,
} from "components/toast/context";

type Props = {
  children: React.ReactNode;
};

const ToastProvider = ({ children }: Props) => {
  const [isToastShown, setIsToastShown] = useState(false);
  const [numTimesToastShown, setNumTimesToastShown] = useState(0);
  const [toastProps, setToastProps] = useState<ToastProps>();
  const showToast = useCallback(
    (props: ToastProps, timeoutDuration?: number) => {
      setToastProps({ ...props, timeoutDuration, useToastFunc: useToast });
      setNumTimesToastShown((prev) => prev + 1);
      setIsToastShown(true);
    },
    [],
  );

  const showToastVariant = useCallback(
    ({
      subtitle,
      title,
      variant,
      button,
      timeoutDuration,
      subtitleComponent,
      parentClassName = "",
      isPersistent,
    }: VariantProps) => {
      setToastProps({
        children: (
          <ToastContents
            variant={variant}
            title={title}
            subtitle={subtitle}
            subtitleComponent={subtitleComponent}
            button={button}
          />
        ),
        parentClassName: `${TOAST_BACKGROUND_COLORS[variant]} ${parentClassName}`,
        timeoutDuration,
        isPersistent,
        useToastFunc: useToast,
      });
      setNumTimesToastShown((prev) => prev + 1);
      setIsToastShown(true);
    },
    [],
  );

  return (
    <ToastContextProvider
      value={{
        isToastShown,
        setIsToastShown,
        showToastVariant,
        showToast,
      }}
    >
      {isToastShown && !!toastProps && (
        <Toast {...toastProps} key={numTimesToastShown} />
      )}
      {children}
    </ToastContextProvider>
  );
};

export default ToastProvider;
