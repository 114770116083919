import { Popper, PopperOwnProps } from "@mui/base";
import { useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  // The component shown on hover
  titleComponent: React.ReactNode;

  popperProps?: Partial<PopperOwnProps>;
  popperClassName?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const ToolTip = ({
  titleComponent,
  children,
  popperProps = {},
  popperClassName = "",
  className,
  ...props
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleToggleHover = useCallback(
    (element: HTMLElement, isVisible: boolean) => {
      setAnchorEl(isVisible ? element : null);
    },
    [],
  );

  return (
    <div
      onMouseEnter={(e) => handleToggleHover(e.target as HTMLElement, true)}
      onMouseLeave={(e) => handleToggleHover(e.target as HTMLElement, false)}
      onFocus={(e) => handleToggleHover(e.target as HTMLElement, true)}
      onBlur={(e) => handleToggleHover(e.target as HTMLElement, false)}
      {...props}
      aria-describedby="tooltip"
      className="cursor-pointer"
    >
      {children}
      <Popper
        id={anchorEl ? "tooltip" : "undefined"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        className="group z-[50]"
        disablePortal
        {...{
          ...popperProps,
          popperOptions: {
            modifiers: [
              ...(popperProps?.modifiers ?? []),
              {
                name: "offset",
                options: {
                  offset: [0, 16],
                },
              },
            ],
          },
        }}
      >
        <div
          className={twMerge(
            "relative z-[50] rounded-lg shadow-tooltip drop-shadow-tooltip",
            popperClassName,
          )}
        >
          {titleComponent}
        </div>
      </Popper>
    </div>
  );
};

export default ToolTip;
