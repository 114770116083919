import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";

import Button from "components/Button";
import LoadingCircle from "components/LoadingCircle";
import PopperContainer from "components/PopperContainer";
import AddCertificateModal from "components/certificates/AddCertificateModal";
import CertificateFilterBreadcrumbs from "components/certificates/CertificateFilterBreadcrumbs";
import CertificateFilterMenu from "components/certificates/CertificateFilterMenu";
import CerticateTableRow from "components/certificates/CertificateTableRow";
import PreviewCertificateModal from "components/certificates/PreviewCertificateModal";
import PreviewSummaryModal from "components/certificates/PreviewSummaryModal";
import PaginatedTable from "components/table";
import useFilter from "hooks/useCoAFilter";
import Certificate from "models/Certificate";
import { useStore } from "stores";
import { CertificateFilter, CertificateFilterSchema } from "types/certificate";
import { Page } from "types/pageResponse";

const HEADER_TITLES = [
  "File Name",
  "Certificate Type",
  "Supplier",
  "Loading Date",
  "Uploaded by",
  "Upload Date",
];

const CLEARED_FILTER_DATA = {
  certType: undefined,
  supplier: undefined,
  createdBy: undefined,
  loadedAt: undefined,
  createdAt: undefined,
};

const Dashboard = () => {
  const { updateParams, detectSearchChange, baseFilter, currentSearch } =
    useFilter(CertificateFilterSchema);
  const {
    authStore,
    certificateStore,
    certTypeStore,
    gradeStore,
    supplierStore,
    userStore,
  } = useStore();
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [detailCert, setDetailCert] = useState<Certificate[]>([]);
  const [previewCertIds, setPreviewCertIds] = useState<number[]>([]);
  const [certificatePage, setCertificatePage] = useState<
    Page<Certificate> | undefined
  >(undefined);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchEntities = useCallback(async () => {
    await userStore.fetchAllUsers(authStore.authHeader);
    await supplierStore.fetchAllSuppliers(authStore.authHeader);
    await certTypeStore.fetchAllCertificateTypes(authStore.authHeader);
    await gradeStore.fetchAllCoalGrades(authStore.authHeader);
  }, [authStore, userStore, supplierStore, certTypeStore]);

  const fetchTableData = useCallback(async () => {
    setIsFetching(true);
    const data = await certificateStore.fetchAllCertificates(
      authStore.authHeader,
      baseFilter,
    );

    setCertificatePage(data as Page<Certificate>);
    setIsFetching(false);
  }, [baseFilter]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  const numAppliedFilters = useMemo(() => {
    const fields = [
      "certType",
      "supplier",
      "createdBy",
      "loadedAt",
      "createdAt",
    ] as const;
    const areFiltersApplied = fields.map(
      (field) => baseFilter[field] !== undefined,
    );

    return areFiltersApplied.reduce((prev, curr) => prev + (curr ? 1 : 0), 0);
  }, [baseFilter]);

  const handleOpenCert = (cert: Certificate) => {
    setOpenDetail(true);
    setDetailCert([cert]);
  };

  const renderRow = useCallback(
    (item: Certificate) => (
      <CerticateTableRow
        key={item.id}
        item={item}
        onClick={() => handleOpenCert(item)}
      />
    ),
    [],
  );

  const onUpload = (ids: number[]) => {
    setPreviewCertIds(ids);
    setOpenPreview(true);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between pt-6">
        <div>
          <h2 className="greeting text-lg font-semibold lg:text-xl">
            Certificate of Analysis
          </h2>
        </div>
        <div className="flex justify-end">
          <Button
            className="text-[13px] lg:text-sm"
            iconName="backup"
            onClick={() => setOpen(true)}
          >
            Upload CoA
          </Button>
        </div>
      </div>

      <div className="relative flex flex-1 flex-col rounded border bg-background shadow-sm">
        <div className="flex items-center justify-between gap-4 px-4 py-2">
          <div className="flex flex-1 items-center gap-3 px-4 py-2">
            <span className="material-icons-outlined leading-none text-base-main/70">
              search
            </span>
            <input
              type="text"
              placeholder="Search"
              className="w-[90px] flex-1 border-0 border-primary-100 px-0 text-sm focus-within:border-b focus:ring-0 lg:w-full lg:text-base"
              value={currentSearch}
              onChange={detectSearchChange}
            />
          </div>

          <PopperContainer
            buttonContent={
              <Button
                className="hidden gap-2 px-4 py-[0.375rem] text-sm lg:flex"
                iconName="filter_list"
                variant="outlined"
              >
                Filter
              </Button>
            }
          >
            <CertificateFilterMenu
              initialData={baseFilter as CertificateFilter}
              onSubmitFilter={(data: CertificateFilter) =>
                updateParams({ ...data, page: undefined })
              }
              clearedData={CLEARED_FILTER_DATA as CertificateFilter}
              isFetching={isFetching}
            />
          </PopperContainer>
        </div>
        {!!numAppliedFilters && (
          <div className="flex items-center justify-between border-b lg:border-b-0 lg:border-t">
            <div className="flex flex-1 flex-row flex-wrap gap-2 px-4 py-2">
              <CertificateFilterBreadcrumbs
                baseFilter={baseFilter}
                onUpdateFilter={updateParams}
              />
            </div>
            <div className="px-4">
              <Button
                className="whitespace-nowrap border-0 px-1 py-4 disabled:bg-transparent"
                onClick={() =>
                  updateParams({
                    ...CLEARED_FILTER_DATA,
                    page: undefined,
                  })
                }
                variant="outlined"
              >
                <span className="hidden lg:block"> Clear Filters </span>
                <span className="material-icons-outlined text-base font-normal leading-none lg:hidden">
                  cancel
                </span>
              </Button>
            </div>
          </div>
        )}
        <div className="relative">
          <PaginatedTable
            className="w-full flex-1"
            headerTitles={HEADER_TITLES}
            currentPage={certificatePage}
            renderRow={renderRow}
            handlePageMove={(page: number) => updateParams({ page })}
            columns={[
              {
                name: "File Name",
                fieldName: "name",
              },
              {
                name: "Certificate Type",
                fieldName: "certType",
              },
              {
                name: "Supplier",
                fieldName: "supplier",
              },
              {
                name: "Loading Date",
                fieldName: "loadedAt",
              },
              {
                name: "Uploaded By",
                fieldName: "createdBy",
              },
              {
                name: "Upload Date",
                fieldName: "createdAt",
              },
            ]}
            canShowAllRows
            showLoadingSkeleton={false}
          />
        </div>
        {isFetching && (
          <div className="absolute left-0 top-0 flex h-full w-full bg-background opacity-75">
            <div className="flex flex-1 items-center justify-center">
              <LoadingCircle size="lg" />
            </div>
          </div>
        )}
        {certificatePage?.results.length === 0 && numAppliedFilters > 0 && (
          <div className="flex flex-1 items-center justify-center">
            <div className="w-96 text-center">
              <p className="material-icons-outlined leading-none text-base-main/70">
                info
              </p>
              <p className="font-semibold">No Certificates of Analysis Found</p>
              <p className="text-sm text-base-main/70">
                It looks like there are no certificates that matches the given
                filters.
              </p>
            </div>
          </div>
        )}
        {certificatePage?.results.length === 0 && numAppliedFilters === 0 && (
          <div className="flex flex-1 items-center justify-center">
            <div className="w-96 text-center">
              <p className="material-icons-outlined leading-none text-base-main/70">
                info
              </p>
              <p className="font-semibold">
                No Certificates of Analysis Available
              </p>
              <p className="text-sm text-base-main/70">
                It looks like there are no certificates to display at the
                moment. Please upload a new certificate to get started.
              </p>
            </div>
          </div>
        )}
      </div>
      <AddCertificateModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmitUpdate={onUpload}
      />
      <PreviewSummaryModal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        certIds={previewCertIds}
        onSubmitUpdate={fetchTableData}
      />
      <PreviewCertificateModal
        open={openDetail}
        onClose={() => setOpenDetail(false)}
        certificates={detailCert}
        onSubmitUpdate={fetchTableData}
      />
    </>
  );
};

export default observer(Dashboard);
