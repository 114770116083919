import { computed } from "mobx";
import {
  Model,
  ModelCreationData,
  _async,
  _await,
  arrayToMapTransform,
  model,
  modelFlow,
  prop,
} from "mobx-keystone";

import CoalGrade from "models/CoalGrade";
import { AuthHeader } from "types/auth";
import { ErrorMessage, API_ERROR_MESSAGES, APIError } from "types/error";
import { ID } from "types/pageResponse";
import fetchAPI from "utils/fetchAPI";

type TypeId = ID<CoalGrade>;

@model("web/CoalGradeStore")
export default class CoalGradeStore extends Model({
  grades: prop<[TypeId, CoalGrade][]>(() => [])
    .withTransform(arrayToMapTransform())
    .withSetter(),
  isReady: prop<boolean>(true).withSetter(),
  errors: prop<ErrorMessage[]>(() => []).withSetter(),
}) {
  @computed
  get gradeList(): CoalGrade[] {
    return Array.from(this.grades.values());
  }

  @modelFlow
  fetchAllCoalGrades = _async(function* (
    this: CoalGradeStore,
    authHeader: AuthHeader,
  ) {
    let response: Response;

    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI("api/coal/grades/", {
          headers: {
            ...authHeader,
          },
        }),
      );
    } catch {
      this.errors.push({
        source: "fetchAllCoalGrades",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }
    const data = (yield* _await(
      response.json(),
    )) as ModelCreationData<CoalGrade>[];

    data.forEach((certType) =>
      this.grades.set(certType.id, new CoalGrade({ ...certType })),
    );
    this.isReady = true;
    return { details: "Successfully fetched", ok: true };
  });

  @modelFlow
  fetchCoalGrade = _async(function* (
    this: CoalGradeStore,
    authHeader: AuthHeader,
    id: number,
  ) {
    let response: Response;

    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI(`api/coal/grades/${id}`, {
          headers: {
            ...authHeader,
          },
        }),
      );
    } catch {
      this.errors.push({
        source: "fetchCoalGrade",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }
    const grade = (yield* _await(
      response.json(),
    )) as ModelCreationData<CoalGrade>;

    this.grades.set(grade.id, new CoalGrade({ ...grade }));
    this.isReady = true;
    return { details: "Successfully fetched", ok: true };
  });
}
