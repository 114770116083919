import {
  Model,
  _async,
  _await,
  model,
  modelAction,
  modelFlow,
  prop,
} from "mobx-keystone";

import AuthStore from "./AuthStore";
import CertificateStore from "./CertificateStore";
import CertificateTypeStore from "./CertificateTypeStore";
import CoalGradeStore from "./GradeStore";
import SupplierStore from "./SupplierStore";
import UserStore from "./UserStore";

@model("web/RootStore")
export default class RootStore extends Model({
  authStore: prop<AuthStore>(),
  certificateStore: prop<CertificateStore>(),
  certTypeStore: prop<CertificateTypeStore>(),
  gradeStore: prop<CoalGradeStore>(),
  supplierStore: prop<SupplierStore>(),
  userStore: prop<UserStore>(),
}) {
  @modelFlow
  load = _async(function* (this: RootStore) {
    // Load auth store separately.
    yield* _await(this.authStore.load());
  });

  @modelAction
  reset = () => {
    this.authStore.reset();
    this.authStore = new AuthStore({});
    this.certificateStore = new CertificateStore({});
    this.certTypeStore = new CertificateTypeStore({});
    this.gradeStore = new CoalGradeStore({});
    this.supplierStore = new SupplierStore({});
    this.userStore = new UserStore({});
  };
}
