import { twMerge } from "tailwind-merge";

type CardProps = {
  title: string;
  titleClassName?: string;
  cardClassName?: string;
  iconComponent?: React.ReactElement;
  children?: React.ReactNode;
};

export default function ReportCard({
  title,
  titleClassName,
  cardClassName,
  iconComponent,
  children,
}: CardProps) {
  return (
    <div
      className={twMerge(
        "flex-1 flex-col rounded-lg bg-background px-3 py-4 shadow-card hover:shadow-xl sm:px-5",
        cardClassName,
      )}
    >
      <div className="flex flex-col border-0">
        <div className="flex flex-1 flex-row gap-2">
          {iconComponent}
          <p
            className={twMerge(
              "text-sm leading-6 tracking-[1px] text-base-main",
              titleClassName,
            )}
          >
            {title}
          </p>
        </div>
        <div className="flex flex-1">{children}</div>
      </div>
    </div>
  );
}
