import { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import Button from "./Button";

type ModalProps = {
  title: string;
  subtitle?: string;
  open: boolean;
  onClose?: () => void;
  hideClose?: boolean;
  className?: string;
  children: ReactNode;
};

const Modal: FC<ModalProps> = ({
  title = "",
  subtitle = "",
  className = "",
  children,
  open = false,
  onClose = () => {},
  hideClose = false,
}) => {
  if (!open) {
    return null;
  }

  // todo: improve scrollbar
  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div
        className={twMerge(
          "w-3/5 min-w-[400px] max-w-[720px] rounded-lg bg-white shadow-lg",
          className,
        )}
      >
        <div className="flex items-center justify-between border-b border-gray-200 p-4">
          <div className="flex flex-col">
            <h3 className="text-xl font-semibold">{title}</h3>
            <p>{subtitle}</p>
          </div>
          {!hideClose && (
            <Button onClick={onClose} variant="icon" iconName="close" />
          )}
        </div>
        <div className="scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 flex max-h-[75vh] min-h-28 flex-col gap-4 overflow-y-auto p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
