import { z } from "zod";

import { getArrayOfKeys } from "types/generic";

type ValueType = string | string[] | number | number[] | boolean;
/**
 * Convert the URLSearch params to a filter object
 */

export function convertSearchParamsToFilter(
  params: URLSearchParams,
  isMultiple?: string[],
) {
  const filter: Record<string, string | string[] | Record<string, string>> = {};

  for (const [key, value] of params) {
    const sequence = key.split("__");

    const data = isMultiple?.includes(key)
      ? value.includes(",")
        ? value.split(",")
        : value
        ? [value]
        : []
      : value;

    if (sequence.length > 1) {
      const [objKey, subkey] = sequence;

      if (!filter.hasOwnProperty(objKey)) {
        filter[objKey] = {};
      }
      const prev = filter[objKey] as Record<string, string | string[]>;

      prev[subkey] = data;
    } else {
      filter[key] = data;
    }
  }
  return filter;
}

export type FilterType = Record<string, ValueType | Record<string, ValueType>>;
export type FilterWithSearchAndPage = FilterType &
  Partial<{ search: string; page: number }>;

/**
 * Converts filter into URLSearchParams.
 *
 * @param {T extends FilterType} filter - Filter to be converted
 * @param {keyof T} forAppend - key of filter which would be appended instead of being parsed as an array, data type of value of this key must be an array.
 * @returns {URLSearchParams} The sum of the two numbers.
 */
export function convertFilterToParams<T extends FilterType>(
  filter: T,
  forAppend?: keyof T | (keyof T)[],
) {
  const result: string[][] = [];

  const forAppendArray = forAppend
    ? Array.isArray(forAppend)
      ? forAppend
      : [forAppend]
    : [];

  for (const key in filter) {
    const data = filter[key];

    const isArray = Array.isArray(data);

    if (isArray) {
      if (forAppendArray.includes(key as keyof T)) {
        data.forEach((data) => result.push([key, data.toString()]));
      } else {
        result.push([key, data.join(",")]);
      }
      continue;
    }

    if (z.string().safeParse(data).success && data) {
      result.push([key, `${data}`]);
      continue;
    }

    if (
      z.number().safeParse(data).success ||
      z.boolean().safeParse(data).success
    ) {
      result.push([key, `${data}`]);
      continue;
    }

    if (!data) {
      continue;
    }

    const subkeys = getArrayOfKeys(data as object);

    subkeys.forEach((subkey) => {
      const subkeyPortion = subkey === "exact" ? "" : `__${String(subkey)}`;

      if (data[subkey]) {
        result.push([`${key}${subkeyPortion}`, `${data[subkey]}`]);
      }
    });
  }

  return new URLSearchParams(result);
}

// TODO: Refactor current sort tables to use these
export function getUpdatedSortFields(
  currentFields: string,
  fieldName: string,
  state: "ascending" | "descending" | "clear",
) {
  // Some fields are suffixes of others e.g. name is a suffix of category_name
  const newOrdering =
    currentFields
      .split(",")
      .filter(
        (field) =>
          !!field && field !== fieldName && field.substring(1) !== fieldName,
      ) ?? [];

  if (state === "clear") {
    return newOrdering.join(",");
  }
  newOrdering?.push(`${state === "descending" ? "-" : ""}${fieldName}`);
  return newOrdering.join(",");
}

export function parseSortedColumns(currentFields: string) {
  return currentFields
    .split(",")
    .filter((field) => !!field)
    .map((field) => {
      const isDescending = field.startsWith("-");

      const a = {
        fieldName: isDescending ? field.slice(1) : field,
        state: isDescending ? "descending" : "ascending",
      } as const;

      return a;
    });
}

export function mergeFilterWithSearchParams<T extends FilterType>(
  baseFilter: T,
  searchParams?: URLSearchParams,
  forAppend?: keyof T | (keyof T)[],
) {
  const mergedQueryParam = convertFilterToParams(baseFilter, forAppend);

  if (searchParams) {
    for (const [key, value] of searchParams.entries()) {
      if (!mergedQueryParam.has(key)) {
        mergedQueryParam.set(key, value);
      }
    }
  }
  return mergedQueryParam;
}
