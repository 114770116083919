import { computed } from "mobx";
import { Model, model, prop } from "mobx-keystone";

@model("web/User")
export default class User extends Model({
  id: prop<number>().withSetter(),
  username: prop<string>("").withSetter(),
  email: prop<string>("").withSetter(),
  role: prop<string>("").withSetter(),
  firstName: prop<string>("").withSetter(),
  lastName: prop<string>("").withSetter(),
}) {
  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
