import {
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  endOfYear,
  startOfYear,
  parseISO,
  isValid,
} from "date-fns";

export const formatDate = (
  date: string,
  datetime = false,
  dateFormat = "dd MMM yyyy",
) => {
  if (date == null) return "-";
  const parsedDate = parseISO(date);

  if (isValid(parsedDate)) {
    if (datetime) dateFormat = "dd MMM yyyy hh:mm a";
    return format(parsedDate, dateFormat);
  }
  return "-";
};

export const naiveDate = (dateStr?: Date | string | null): Date => {
  let date = new Date();

  if (dateStr) {
    date = new Date(dateStr);
  }
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
};

export const formatDateRange = (
  from: Date | undefined,
  to: Date | undefined,
  withYear: boolean = true,
) => {
  if (!from || !to) return "";

  const yearFormat = withYear ? ", yyyy" : "";

  if (isSameDay(from, startOfYear(from)) && isSameDay(to, endOfYear(to))) {
    return format(from, "yyyy");
  } else if (!isSameYear(from, to)) {
    return `${format(from, "MMM dd, yyyy")} - ${format(to, "MMM dd, yyyy")}`;
  } else if (!isSameMonth(from, to)) {
    return `${format(from, "MMM dd")} - ${format(to, "MMM dd" + yearFormat)}`;
  } else if (!isSameDay(from, to)) {
    return `${format(from, "MMM dd")} - ${format(to, "dd" + yearFormat)}`;
  }

  return `${format(from, "MMM dd, yyyy")}`;
};

export function areSameDates(a: Date, b: Date) {
  return a.getTime() === b.getTime();
}
