import { ClickAwayListener, Popper, PopperOwnProps } from "@mui/base";
import React, { useCallback, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  buttonContent: React.ReactNode;
  children?: React.ReactNode;

  popperProps?: Partial<PopperOwnProps>;
};

const PopperContainer = ({
  buttonContent,
  children,
  className,
  popperProps = {
    placement: "bottom-end",
  },
  ...props
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prev) =>
      prev ? null : (event.target as HTMLElement).parentElement,
    );
  }, []);

  return (
    <>
      {/* overlay */}
      {anchorEl && (
        <div
          className={`${
            anchorEl &&
            "fixed left-0 top-0 z-40 block h-screen w-screen lg:hidden"
          } flex-none`}
        />
      )}
      <ClickAwayListener
        onClickAway={() => setAnchorEl(null)}
        mouseEvent="onPointerDown"
      >
        <div className={className}>
          <div
            onClick={handleClick}
            aria-describedby="popper"
            className="cursor-pointer"
            role="button"
          >
            {buttonContent}
          </div>
          <Popper
            id={anchorEl ? "popper" : "undefined"}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            {...popperProps}
            className="z-[50]"
            disablePortal
          >
            <div
              className={twMerge(
                "z-[50] mt-1 whitespace-nowrap rounded-md bg-background shadow-popover",
                className,
              )}
              {...props}
            >
              <PopperProvider
                value={{
                  signalClose: () => setAnchorEl(null),
                }}
              >
                {children}
              </PopperProvider>
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default PopperContainer;

type PopperContextData = {
  signalClose: () => void;
};

export const PopperContext = React.createContext<PopperContextData>(
  {} as PopperContextData,
);
export const usePopperContainer = () => React.useContext(PopperContext);
export const { Provider: PopperProvider } = PopperContext;

const BASE_BUTTON_CLASSES =
  "py-[0.375rem] pl-4 pr-[.8125rem] text-left text-base leading-6 hover:bg-blue-lighter";

type PopperButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const PopperButton = ({
  className,
  children,
  ...props
}: PopperButtonProps) => {
  return (
    <button
      {...props}
      className={twMerge(BASE_BUTTON_CLASSES, className)}
      type="button"
    >
      {children}
    </button>
  );
};

export const PopperLink = ({ className, children, ...props }: LinkProps) => {
  const { signalClose } = usePopperContainer();

  return (
    <Link
      {...props}
      className={twMerge(BASE_BUTTON_CLASSES, className)}
      onClick={signalClose}
    >
      {children}
    </Link>
  );
};

export const PopperExternalLink = ({
  className,
  children,
  ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { signalClose } = usePopperContainer();

  return (
    <a
      {...props}
      className={twMerge(BASE_BUTTON_CLASSES, className)}
      onClick={signalClose}
    >
      {children}
    </a>
  );
};
