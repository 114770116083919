import { computed } from "mobx";
import { Model, model, prop } from "mobx-keystone";

@model("web/CertificateData")
export class CertificateData extends Model({
  id: prop<number>().withSetter(),
  category: prop<string>("").withSetter(),
  certificate: prop<number>().withSetter(),
  label: prop<string>("").withSetter(),
  units: prop<string>("").withSetter(),
  value: prop<string>("").withSetter(),
  asDetermined: prop<string | null>(null).withSetter(),
  asReceived: prop<string | null>(null).withSetter(),
  dryBasis: prop<string | null>(null).withSetter(),
  oxidizing: prop<string | null>(null).withSetter(),
  reducing: prop<string | null>(null).withSetter(),
}) {}

@model("web/Deviation")
export class Deviation extends Model({
  key: prop<string>("").withSetter(),
  category: prop<string>("").withSetter(),
  label: prop<string>("").withSetter(),
  field: prop<string>("").withSetter(),
}) {}

@model("web/Certificate")
export default class Certificate extends Model({
  id: prop<number>().withSetter(),
  name: prop<string>("").withSetter(),
  file: prop<string>("").withSetter(),
  certType: prop<number>().withSetter(),
  supplier: prop<number>().withSetter(),
  data: prop<CertificateData[]>(() => []).withSetter(),
  deviations: prop<Deviation[]>(() => []).withSetter(),
  isConfirmed: prop<boolean>(false).withSetter(),
  loadedAt: prop<string>("").withSetter(),
  createdBy: prop<number>().withSetter(),
  createdAt: prop<string>("").withSetter(),
}) {
  @computed
  get hasDeviation() {
    return this.deviations.length > 0;
  }
}
