import { useEffect, useRef } from "react";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";

type LoadingSuspenseProps = {
  barColor?: string;
};

export default function LoadingSuspense({
  barColor = "#C8DA2B",
}: LoadingSuspenseProps) {
  const ref = useRef<LoadingBarRef>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.continuousStart();
    }
  }, []);
  return (
    <div>
      <LoadingBar color={barColor} ref={ref} shadow />
    </div>
  );
}
