import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ZodTypeDef, z } from "zod";

import {
  FilterWithSearchAndPage,
  convertFilterToParams,
  convertSearchParamsToFilter,
} from "utils/filtering";

import useDebouncedInput from "./useDebouncedInput";

export default function useFilter<
  T extends FilterWithSearchAndPage,
  K extends ZodTypeDef &
    z.ZodObjectDef<{
      search: z.ZodOptional<z.ZodString>;
      page: z.ZodOptional<z.ZodNumber>;
    }>,
  Input = T,
>(schema: z.ZodType<T, K, Input>, isMultiple?: string[]) {
  const [searchParams, setSearchParams] = useSearchParams();

  const baseFilter = useMemo(() => {
    const result = schema.safeParse(
      convertSearchParamsToFilter(searchParams, isMultiple),
    );

    return (result.success ? result.data : {}) as z.infer<typeof schema>;
  }, [searchParams, schema]);

  const updateParams = useCallback(
    (newData: z.infer<typeof schema>) => {
      setSearchParams(
        convertFilterToParams<T>({
          ...baseFilter,
          ...newData,
        }),
      );
    },
    [baseFilter, setSearchParams],
  );

  const updateParamsWithSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateParams({
        search: event.target.value,
        page: undefined,
      } as T);
    },
    [updateParams],
  );

  const [currentSearch, setCurrentSearch] = useState(baseFilter.search || "");

  const { detectInputChange } = useDebouncedInput(updateParamsWithSearch);
  const detectSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentSearch(e.target.value);
      detectInputChange(e);
    },
    [detectInputChange],
  );

  useEffect(() => {
    if (!baseFilter.search) {
      setCurrentSearch("");
    }
  }, [baseFilter.search]);

  return { updateParams, detectSearchChange, baseFilter, currentSearch };
}
