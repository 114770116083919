import {
  format,
  getISODay,
  isSameDay,
  isWithinInterval,
  max,
  min,
} from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { twMerge } from "tailwind-merge";

import Button from "components/Button";
import { usePopperContainer } from "components/PopperContainer";
import { naiveDate } from "utils/dates";

import { generateCalendar } from "./generateCalendar";

type DateRange = {
  start: Date;
  end: Date;
  isFinal: boolean;
};

type DateRangePickerProps = {
  initialRange?: Omit<DateRange, "isFinal">;
  onSubmit: (start: Date, end: Date, type: string) => void;
  onCancel: () => void;
  dateTypeValue?: string;
  isRange?: boolean;
  cancelOrSubmitToClose?: boolean;
};

type Screen = "YearSelect" | "MonthSelect" | "DateRange";

const DateRangePicker = ({
  initialRange,
  onSubmit,
  onCancel,
  dateTypeValue = "",
  isRange = true,
  cancelOrSubmitToClose = false,
}: DateRangePickerProps) => {
  const [currRange, setCurrRange] = useState<DateRange | undefined>(
    initialRange ? { ...initialRange, isFinal: true } : undefined,
  );
  const [dateType, setDateType] = useState<string>(dateTypeValue);
  const { signalClose } = usePopperContainer();

  const [screen, setScreen] = useState<Screen>("DateRange");
  const [filter, setFilter] = useState<{ month: number; year: number }>({
    month: initialRange
      ? initialRange.start.getMonth()
      : naiveDate().getMonth(),
    year: initialRange
      ? initialRange.start.getFullYear()
      : naiveDate().getFullYear(),
  });
  const calendar = useMemo(
    () => generateCalendar(filter.month, filter.year),
    [filter.year, filter.month],
  );

  const handleClick = useCallback((date: Date) => {
    setCurrRange((prev) => {
      if (!isRange) {
        return { start: date, end: date, isFinal: true };
      }
      if (!!prev && !prev.isFinal) {
        return { ...prev, isFinal: !prev.isFinal };
      }
      return { start: date, end: date, isFinal: false };
    });
    setDateType("Custom");
  }, []);

  const handleMonthChange = useCallback((offset: -1 | 1) => {
    setFilter((prev) => {
      const month = (((prev.month + offset) % 12) + 12) % 12;
      const year = prev.year + (month !== prev.month + offset ? offset : 0);

      return { month, year };
    });
  }, []);

  const handleHover = useCallback((date: Date) => {
    setCurrRange((prev) =>
      !!prev && !prev.isFinal
        ? {
            ...prev,
            end: date,
          }
        : prev,
    );
  }, []);

  const handleSubmit = useCallback(() => {
    if (!currRange) {
      return;
    }
    onSubmit(
      min([currRange.start, currRange.end]),
      max([currRange.start, currRange.end]),
      dateType,
    );
    if (!isRange || cancelOrSubmitToClose) {
      signalClose();
    }
  }, [currRange, onSubmit, dateType]);

  return (
    <div className="flex flex-col rounded-2xl text-sm ">
      <div className="flex flex-col lg:flex-row lg:gap-5 lg:p-5">
        {/* Date Picker */}
        <div className="">
          <div
            className={twMerge(
              "grid min-w-[80vw] place-items-center py-3 lg:min-w-[27.75rem]",
              screen !== "DateRange" ? "border-b" : "",
            )}
          >
            <div className="flex w-full flex-1 items-center justify-between px-4 ">
              {/** Month Select */}
              <button
                type="button"
                className={twMerge(
                  "material-icons bg-basic-primary flex aspect-square h-10 w-10 items-center justify-center px-4 text-primary-900",
                  screen !== "DateRange" ? "opacity-0" : "opacity-100",
                )}
                onClick={() => handleMonthChange(-1)}
              >
                chevron_left
              </button>
              <div className="flex flex-1 justify-center xs:gap-2">
                <div className="flex-1" />
                <button
                  type="button"
                  className="flex flex-1 flex-row items-center justify-center gap-2 px-2"
                  onClick={() =>
                    setScreen((prev) =>
                      prev === "MonthSelect" ? "DateRange" : "MonthSelect",
                    )
                  }
                >
                  <span
                    className={twMerge(
                      "font-semibold text-base-main",
                      screen === "YearSelect" ? "opacity-70" : "opacity-100",
                    )}
                  >
                    {format(new Date(filter.year, filter.month), "MMM")}
                  </span>
                  <div className="material-icons aspect-square w-[1.125rem] text-xl leading-none text-base-main/70">
                    expand_more
                  </div>
                </button>
                {/** Year select */}
                <button
                  type="button"
                  className="flex flex-1 flex-row items-center justify-center gap-2 px-2"
                  onClick={() =>
                    setScreen((prev) =>
                      prev === "YearSelect" ? "DateRange" : "YearSelect",
                    )
                  }
                >
                  <span
                    className={twMerge(
                      "font-semibold text-base-main",
                      screen === "MonthSelect" ? "opacity-70" : "opacity-100",
                    )}
                  >
                    {filter.year}
                  </span>
                  <div className="material-icons aspect-square w-[1.125rem] text-xl leading-none text-base-main/70">
                    expand_more
                  </div>
                </button>
                <div className="flex-1" />
              </div>
              <button
                type="button"
                className={twMerge(
                  "material-icons bg-basic-primary flex aspect-square w-10 items-center justify-center px-4 text-primary-900",
                  screen !== "DateRange" ? "opacity-0" : "opacity-100",
                )}
                onClick={() => handleMonthChange(1)}
              >
                chevron_right
              </button>
            </div>
          </div>
          {screen === "MonthSelect" && (
            <SelectMonth
              currentMonth={filter.month}
              onSelectMonth={(month) => {
                setScreen("DateRange");
                setFilter((prev) => ({ ...prev, month }));
              }}
            />
          )}
          {screen === "YearSelect" && (
            <SelectYear
              currentYear={filter.year}
              onSelectYear={(year) => {
                setScreen("DateRange");
                setFilter((prev) => ({ ...prev, year }));
              }}
            />
          )}
          <hr className="block" />
          {screen === "DateRange" && (
            <div className="p-4">
              <div className="grid grid-cols-7 px-4">
                <span className="grid aspect-square place-items-center">
                  Su
                </span>
                <span className="grid aspect-square place-items-center">
                  Mo
                </span>
                <span className="grid aspect-square place-items-center">
                  Tu
                </span>
                <span className="grid aspect-square place-items-center">
                  We
                </span>
                <span className="grid aspect-square place-items-center">
                  Th
                </span>
                <span className="grid aspect-square place-items-center">
                  Fr
                </span>
                <span className="grid aspect-square place-items-center">
                  Sa
                </span>
                {calendar.map(({ date }) => (
                  <CalendarCell
                    key={date.toISOString()}
                    date={date}
                    range={currRange}
                    filter={filter}
                    onClick={() => handleClick(date)}
                    onMouseOver={() => handleHover(date)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Buttons */}
      <hr className="block" />
      <div className="flex justify-end gap-5 px-4 py-3">
        <button
          type="button"
          className="text-[13px] font-semibold text-base-main"
          onClick={cancelOrSubmitToClose ? () => signalClose() : onCancel}
        >
          Cancel
        </button>
        <Button
          type="button"
          variant="outlined"
          className="text-[13px] font-semibold text-primary-900"
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default DateRangePicker;

const START_YEAR = 1970;

type SelectMonthProps = {
  currentMonth: number;
  onSelectMonth: (month: number) => void;
};

const SelectMonth = ({ onSelectMonth, currentMonth }: SelectMonthProps) => {
  return (
    <div className="flex max-h-80 flex-col overflow-y-auto">
      {new Array(12)
        .fill(0)
        .map((v, idx) => idx)
        .map((v) => (
          <button
            key={v}
            type="button"
            onClick={() => onSelectMonth(v)}
            className={twMerge(
              "flex items-center gap-4 p-4 text-left hover:bg-primary-bg",
              v === currentMonth
                ? "rounded-lg bg-primary-bg font-semibold text-primary-900"
                : "",
            )}
          >
            <div className="h-6 w-6">
              {v === currentMonth && (
                <span className="material-icons">check</span>
              )}
            </div>
            {format(new Date(START_YEAR, v), "MMMM")}
          </button>
        ))}
    </div>
  );
};

type SelectYearProps = {
  currentYear: number;
  onSelectYear: (year: number) => void;
};

const SelectYear = ({ onSelectYear, currentYear }: SelectYearProps) => {
  const indexOfCurrYear = useMemo(
    () => new Date().getFullYear() - START_YEAR,
    [],
  );
  const [maxYearDist, setMaxYearDist] = useState(indexOfCurrYear * 2);

  return (
    <div className="overflow-hidden">
      <InfiniteLoader
        isItemLoaded={(index) => maxYearDist > index + 1}
        loadMoreItems={() => setMaxYearDist((prev) => prev + 10)}
        itemCount={maxYearDist}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            onItemsRendered={onItemsRendered}
            ref={ref}
            width="100%"
            itemSize={50}
            initialScrollOffset={indexOfCurrYear * 50}
            itemCount={maxYearDist}
            height={320}
          >
            {({ index, style }) => (
              <li
                style={style}
                className={twMerge(
                  "flex list-none items-center hover:bg-primary-bg",
                  index === currentYear - START_YEAR
                    ? "bg-primary-bg/[0.08] font-semibold text-primary-900"
                    : "",
                )}
              >
                <button
                  type="button"
                  className="flex flex-1 items-center gap-4 p-4 text-left"
                  onClick={() => onSelectYear(START_YEAR + index)}
                >
                  <div className="h-6 w-6">
                    {index === currentYear - START_YEAR && (
                      <span className="material-icons">check</span>
                    )}
                  </div>
                  {START_YEAR + index}
                </button>
              </li>
            )}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </div>
  );
};

type CalendarCellProps = {
  date: Date;
  range?: DateRange;
  filter: {
    month: number;
    year: number;
  };
  onClick: () => void;
  onMouseOver: () => void;
};

const CalendarCell = ({
  date,
  range,
  filter: { month, year },
  onClick,
  onMouseOver,
}: CalendarCellProps) => {
  const properRange = useMemo(() => {
    if (range === undefined) {
      return undefined;
    }

    const start = min([range.start, range.end]);
    const end = max([range.start, range.end]);

    return { ...range, start, end };
  }, [range]);

  const endpointCell = useMemo(() => {
    if (!properRange) {
      return undefined;
    }
    return (
      (isSameDay(date, properRange.start) !==
        isSameDay(date, properRange.end) ||
        (isSameDay(properRange.start, properRange.end) &&
          isSameDay(properRange.start, date))) && (
        <div className="absolute left-1/2 top-1/2 z-[2] grid aspect-square w-full -translate-x-1/2 -translate-y-1/2 place-items-center rounded-full bg-primary-100 text-white">
          {date.getDate()}
        </div>
      )
    );
  }, [date, properRange]);

  const isoDay = useMemo(() => getISODay(date), [date]);

  const isInRange = useMemo(
    () => !!properRange && isWithinInterval(date, properRange),
    [date, properRange],
  );

  const isInMonth = useMemo(() => date.getMonth() === month, [date, month]);

  return (
    <button
      type="button"
      className={twMerge(
        "relative border-transparent",
        !isInRange
          ? "hover:rounded-full hover:border hover:border-primary-900"
          : "",
      )}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      <span
        className={twMerge(
          "grid aspect-square place-items-center rounded-full",
          determineShading(date, properRange),
          isInRange &&
            isoDay === 7 &&
            !!properRange &&
            !isSameDay(properRange.start, date)
            ? "-ml-3 -mr-0 aspect-auto h-full pl-3"
            : "",
          isInRange &&
            isoDay === 6 &&
            !!properRange &&
            !isSameDay(properRange.end, date)
            ? "-ml-0 -mr-3 aspect-auto h-full pr-3"
            : "",
          !!properRange && isSameDay(date, properRange.start)
            ? "rounded-l-full"
            : "",
          !!properRange && isSameDay(date, properRange.end)
            ? "rounded-r-full"
            : "",
          isInMonth ? "" : "text-base-main/50",
        )}
      >
        {date.getDate()}
      </span>
      {endpointCell}
    </button>
  );
};

function determineShading(date: Date, range?: DateRange) {
  if (range === undefined || !isWithinInterval(date, range)) {
    return "";
  }
  return "bg-primary-bg text-primary-100 rounded-none";
}
