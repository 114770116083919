import { observer } from "mobx-react-lite";
import { type TableHTMLAttributes } from "react";

import Button from "components/Button";
import Certificate from "models/Certificate";
import { formatDate } from "utils/dates";

type PreviewTableRowProps = TableHTMLAttributes<HTMLTableRowElement> & {
  item: Certificate;
  onClick?: () => void;
};

const PreviewTableRow = observer(
  ({ className, item, onClick = () => {}, ...props }: PreviewTableRowProps) => {
    if (!item) {
      return <></>;
    }

    return (
      <tr
        key={item.id}
        className={`text-sm hover:cursor-pointer hover:bg-primary-bg ${className}`}
        {...props}
      >
        <td className="p-4">{item.name}</td>
        <td className="p-4">
          {item.hasDeviation ? (
            <p className="text-danger-400">Deviations Detected</p>
          ) : (
            "No Deviations"
          )}
        </td>
        <td className="p-4">
          {item.isConfirmed ? "Confirmed" : "Unconfirmed"}
        </td>
        <td className="flex justify-between p-4">
          {formatDate(item.createdAt)}
          <Button onClick={onClick} variant="icon" iconName="arrow_forward" />
        </td>
      </tr>
    );
  },
);

export default PreviewTableRow;
