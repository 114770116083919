import { registerRootStore } from "mobx-keystone";
import React from "react";

import AuthStore from "./AuthStore";
import CertificateStore from "./CertificateStore";
import CertificateTypeStore from "./CertificateTypeStore";
import CoalGradeStore from "./GradeStore";
import RootStore from "./RootStore";
import SupplierStore from "./SupplierStore";
import UserStore from "./UserStore";

export const StoreContext = React.createContext<RootStore>({} as RootStore);
export const useStore = () => React.useContext(StoreContext);
export const { Provider: StoreProvider } = StoreContext;

export function createRootStore() {
  const authStore = new AuthStore({});
  const certificateStore = new CertificateStore({});
  const certTypeStore = new CertificateTypeStore({});
  const gradeStore = new CoalGradeStore({});
  const supplierStore = new SupplierStore({});
  const userStore = new UserStore({});

  const rootStore = new RootStore({
    authStore,
    certificateStore,
    certTypeStore,
    gradeStore,
    supplierStore,
    userStore,
  });

  registerRootStore(rootStore);
  return rootStore;
}
