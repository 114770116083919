import { ScatterCustomizedShape } from "recharts/types/cartesian/Scatter";

const SUPPLIER_REPORT_COLOR_CHOICES = [
  "#64B1F7",
  "#6CCE81",
  "#F6AD34",
  "#CE1A1D",
];
const SUPPLIER_REPORT_SHAPE_CHOICES = ["circle", "square", "star"];

export const getReportColorForId = (id: string | number) => {
  const numberId = typeof id === "string" ? parseInt(id, 10) : id;

  return SUPPLIER_REPORT_COLOR_CHOICES[
    numberId % SUPPLIER_REPORT_COLOR_CHOICES.length
  ];
};

export const getReportShapeForId = (id: string | number) => {
  const numberId = typeof id === "string" ? parseInt(id, 10) : id;

  return SUPPLIER_REPORT_SHAPE_CHOICES[
    numberId % SUPPLIER_REPORT_SHAPE_CHOICES.length
  ] as ScatterCustomizedShape;
};
