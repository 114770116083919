import { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import LoadingCircle from "./LoadingCircle";

type ButtonProps = {
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  variant?: "contained" | "outlined" | "text" | "icon";
  isLoading?: boolean;
  iconName?: string;
  useOutlinedIcon?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
};

const Button: FC<ButtonProps> = ({
  onClick = () => {},
  type = "button",
  variant = "contained",
  fullWidth = false,
  disabled = false,
  isLoading = false,
  iconName = "",
  useOutlinedIcon = false,
  className = "",
  children = null,
}) => {
  const baseClasses =
    "gap-2 rounded px-3 py-1.5 font-semibold flex items-center justify-center";
  const variantClasses = {
    text: "text-primary-100 disabled:text-gray-400",
    icon: "text-primary-100 disabled:text-gray-400 p-0",
    outlined:
      "border border-primary-100 text-primary-100 disabled:border-gray-200 disabled:text-gray-400",
    contained:
      "bg-primary-100 text-white disabled:bg-gray-200 disabled:text-gray-400",
  };

  const fullWidthClass = fullWidth ? "w-full" : "";

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={twMerge(
        baseClasses,
        variantClasses[variant],
        fullWidthClass,
        className,
      )}
    >
      {isLoading && <LoadingCircle size="sm" />}
      {iconName && !isLoading && (
        <div
          className={twMerge(
            "flex items-center justify-end",
            useOutlinedIcon ? "material-icons-outlined" : "material-icons",
          )}
        >
          <div className="grid place-items-center">
            <span>{iconName}</span>
          </div>
        </div>
      )}
      {children}
    </button>
  );
};

export default Button;
