import { useCallback, useState } from "react";

export default function useDebouncedInput<T>(
  delayedEventHandler: React.ChangeEventHandler<T>,
  timeoutDuration: number = 500,
) {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();

  const detectInputChange = useCallback(
    (event: React.ChangeEvent<T>) => {
      clearTimeout(timer);
      setTimer(setTimeout(() => delayedEventHandler(event), timeoutDuration));
    },
    [delayedEventHandler, timeoutDuration, timer],
  );

  return { detectInputChange };
}
