import { observer } from "mobx-react-lite";
import { Navigate, useLocation } from "react-router-dom";

import { useStore } from "stores";

type Props = {
  children: React.ReactNode;
};

const ProtectedRoute = ({ children }: Props) => {
  const { authStore } = useStore();
  const location = useLocation();

  if (!authStore.isAuthenticated) {
    const redirectPath =
      `/login?next=${location.pathname}${location.search}` || "/login";

    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>;
};

export default observer(ProtectedRoute);
