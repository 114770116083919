const TableSkeleton = ({ numRows = 10 }) => {
  const rows = Array.from({ length: numRows }, (_, index) => (
    <tr key={index}>
      <td className="p-3 pr-0">
        <div className="bg-dark-100 h-[12.25px] w-[30px] rounded-full" />
      </td>
      <td className="p-4">
        <div
          className={`bg-dark-100 h-[12.25px] w-[${
            index % 2 === 0 ? "150px" : "100px"
          }] rounded-full`}
        />
      </td>
      <td className="p-4">
        <div className="bg-dark-100 h-[12.25px] w-[100px] rounded-full" />
      </td>
      <td className="p-4">
        <div
          className={`bg-dark-100 h-[12.25px] w-[${
            index % 2 === 0 ? "120px" : "150px"
          }] rounded-full`}
        />
      </td>
      <td className="p-4">
        <div
          className={`bg-dark-100 h-[12.25px] w-[${
            index % 2 === 0 ? "30px" : "20px"
          }] rounded-full`}
        />
      </td>
      <td className="p-4">
        <div
          className={`bg-dark-100 h-[12.25px] w-[${
            index % 2 === 0 ? "100px" : "120px"
          }] rounded-full`}
        />
      </td>
      <td className="p-4">
        <div
          className={`bg-dark-100 h-[12.25px] w-[${
            index % 2 === 0 ? "120px" : "100px"
          }] rounded-full`}
        />
      </td>
    </tr>
  ));

  return (
    <table className="w-full flex-1">
      <thead>
        <tr className="border-y">
          <th className="bg-primary-50 p-3 pr-0">
            <div className="bg-dark-100 h-[12.25px] w-[15px] rounded-full" />
          </th>
          <th className="bg-primary-50 p-4">
            <div className="bg-dark-100 h-[12.25px] w-[50px] rounded-full" />
          </th>
          <th className="bg-primary-50 p-4">
            <div className="bg-dark-100 h-[12.25px] w-[50px] rounded-full" />
          </th>
          <th className="bg-primary-50 p-4">
            <div className="bg-dark-100 h-[12.25px] w-[100px] rounded-full" />
          </th>
          <th className="bg-primary-50 p-4">
            <div className="bg-dark-100 h-[12.25px] w-[100px] rounded-full" />
          </th>
          <th className="bg-primary-50 p-4">
            <div className="bg-dark-100 h-[12.25px] w-[100px] rounded-full" />
          </th>
          <th className="bg-primary-50 p-4">
            <div className="bg-dark-100 h-[12.25px] w-[100px] rounded-full" />
          </th>
        </tr>
      </thead>
      <tbody className="[&>]:border-x [&>tr:not(:last-child)]:border-b">
        {rows}
      </tbody>
    </table>
  );
};

export default TableSkeleton;
