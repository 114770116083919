import { observer } from "mobx-react-lite";
import { useMemo, type TableHTMLAttributes } from "react";

import Button from "components/Button";
import Certificate from "models/Certificate";
import { useStore } from "stores";
import { formatDate } from "utils/dates";

type CerticateTableRowProps = TableHTMLAttributes<HTMLTableRowElement> & {
  item: Certificate;
  onClick: () => void;
};

const CerticateTableRow = observer(
  ({ className, item, onClick, ...props }: CerticateTableRowProps) => {
    const { certTypeStore, supplierStore, userStore } = useStore();

    const uploaderName = useMemo(() => {
      if (item.createdBy && userStore.users.get(item.createdBy)) {
        return userStore.users.get(item.createdBy)?.fullName;
      }
    }, [item, userStore.userList]);

    const supplierName = useMemo(() => {
      if (item.supplier && supplierStore.suppliers.get(item.supplier)) {
        return supplierStore.suppliers.get(item.supplier)?.name;
      }
    }, [item, supplierStore.supplierList]);

    const certType = useMemo(() => {
      if (item.certType && certTypeStore.types.get(item.certType)) {
        return certTypeStore.types.get(item.certType);
      }
    }, [item, certTypeStore.typeList]);

    if (!item) {
      return <></>;
    }

    return (
      <tr
        key={item.id}
        className={`text-sm hover:cursor-pointer hover:bg-primary-bg ${className}`}
        {...props}
      >
        <td className="p-4">{item.name}</td>
        <td className="p-4">
          <div
            className={`w-fit rounded-2xl px-[14px] py-1 text-xs leading-5 bg-badge-${certType?.key}`}
          >
            {certType?.name || "N/A"}
          </div>
        </td>
        <td className="p-4">{supplierName || "N/A"}</td>
        <td className="p-4">{formatDate(item.loadedAt)}</td>
        <td className="p-4">{uploaderName}</td>
        <td className="flex justify-between p-4">
          {formatDate(item.createdAt)}
          <Button onClick={onClick} variant="icon" iconName="arrow_forward" />
        </td>
      </tr>
    );
  },
);

export default CerticateTableRow;
