import { computed } from "mobx";
import {
  Model,
  ModelCreationData,
  _async,
  _await,
  arrayToMapTransform,
  model,
  modelFlow,
  prop,
} from "mobx-keystone";

import User from "models/User";
import { AuthHeader } from "types/auth";
import { ErrorMessage, API_ERROR_MESSAGES, APIError } from "types/error";
import { ID, Page } from "types/pageResponse";
import fetchAPI from "utils/fetchAPI";

type UserId = ID<User>;

@model("web/UserStore")
export default class UserStore extends Model({
  users: prop<[UserId, User][]>(() => [])
    .withTransform(arrayToMapTransform())
    .withSetter(),
  isReady: prop<boolean>(true).withSetter(),
  errors: prop<ErrorMessage[]>(() => []).withSetter(),
}) {
  @computed
  get userList(): User[] {
    return Array.from(this.users.values());
  }

  @modelFlow
  fetchAllUsers = _async(function* (this: UserStore, authHeader: AuthHeader) {
    let response: Response;

    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI("api/users/", {
          headers: {
            ...authHeader,
          },
        }),
      );
    } catch {
      this.errors.push({
        source: "fetchAllUsers",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }
    const data = (yield* _await(response.json())) as Page<
      ModelCreationData<User>
    >;

    data.results.forEach((user) =>
      this.users.set(user.id, new User({ ...user })),
    );
    this.isReady = true;
    return { details: "Successfully fetched", ok: true };
  });

  @modelFlow
  fetchUser = _async(function* (
    this: UserStore,
    authHeader: AuthHeader,
    id: number,
  ) {
    let response: Response;

    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI(`api/users/${id}`, {
          headers: {
            ...authHeader,
          },
        }),
      );
    } catch {
      this.errors.push({
        source: "fetchUser",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }
    const user = (yield* _await(response.json())) as ModelCreationData<User>;

    this.users.set(user.id, new User({ ...user }));
    this.isReady = true;
    return { details: "Successfully fetched", ok: true };
  });
}
