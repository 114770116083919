/* eslint-disable no-unused-vars */
import { observer } from "mobx-react-lite";
import { FC, useCallback, useState, useMemo } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "components/Button";
import ConfirmModal from "components/ConfirmModal";
import Modal from "components/Modal";
import { Table } from "components/table";
import { useToast } from "components/toast/context";
import Certificate from "models/Certificate";
import { useStore } from "stores";

import PreviewCertificateModal from "./PreviewCertificateModal";
import PreviewTableRow from "./PreviewTableRow";

type PreviewSummaryModalProps = {
  certIds: number[];
  open: boolean;
  onClose?: () => void;
  onSubmitUpdate?: () => void;
};

type PreviewSummaryFormInputs = {
  certType: string;
  files: File[];
};

const HEADER_TITLES = ["File Name", "Status", "Confirmation", "Upload Date"];

const PreviewSummaryModal: FC<PreviewSummaryModalProps> = ({
  certIds,
  open = false,
  onClose = () => {},
  onSubmitUpdate = () => {},
}) => {
  const {
    // register,
    handleSubmit,
    // formState: { errors },
  } = useForm<PreviewSummaryFormInputs>();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openedCert, setOpenedCert] = useState<Certificate[]>([]);
  const [openPreview, setOpenPreview] = useState(false);
  const { certificateStore, authStore } = useStore();
  const { showToastVariant } = useToast();

  const handleClickCert = (cert: Certificate) => {
    setOpenedCert([cert]);
    setOpenPreview(true);
  };

  const handleCloseConfirm = async () => {
    onClose();
    setOpenConfirm(false);

    // send delete API request
    await certificateStore.deleteCertificates(authStore.authHeader, certIds);
  };

  const certificates = useMemo(
    () =>
      certificateStore.certificateList.filter((cert) =>
        certIds.includes(cert.id),
      ),
    [certificateStore.certificateList, certIds],
  );

  const onSubmit: SubmitHandler<PreviewSummaryFormInputs> = async (data) => {
    console.log("data submitted:", data);
    const response = await certificateStore.confirmCertificates(
      authStore.authHeader,
      certIds,
    );

    if (response.ok) {
      showToastVariant({
        variant: "success",
        title: "Success",
        subtitle: "Successfully uploaded certificates",
      });
      onSubmitUpdate();
      onClose();
    } else {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: response.details as string,
      });
    }
  };

  const renderRow = useCallback(
    (item: Certificate) => (
      <PreviewTableRow
        key={item.id}
        item={item}
        onClick={() => handleClickCert(item)}
      />
    ),
    [certificates],
  );

  return (
    <>
      <Modal
        title="Uploaded Certificates"
        subtitle="Confirm if values are correct."
        className="w-1/2 min-w-[480px] max-w-[1920px]"
        open={open}
        onClose={onClose}
        hideClose
      >
        <form className="min-h-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex min-h-full flex-col justify-between gap-4">
            <Table
              className="w-full flex-1"
              headerTitles={HEADER_TITLES}
              renderRow={renderRow}
              items={certificates}
              showLoadingSkeleton={false}
            />
            <div className="flex justify-end gap-2 border-t border-gray-200">
              <Button
                fullWidth
                onClick={() => setOpenConfirm(true)}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button fullWidth type="submit" variant="contained">
                Confirm All Files
              </Button>
            </div>
          </div>
        </form>
      </Modal>
      <ConfirmModal
        message="All certificates will be lost."
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleCloseConfirm}
      />
      <PreviewCertificateModal
        certificates={openedCert}
        open={openPreview}
        onClose={() => setOpenPreview(false)}
      />
    </>
  );
};

export default observer(PreviewSummaryModal);
