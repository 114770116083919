import { kebabCase } from "lodash";
import React, { MouseEventHandler, useMemo } from "react";
import { FieldError, GlobalError } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import ArrowDropDown from "../assets/arrow_drop_down.svg";

export type WithError<T> = T & {
  error?: FieldError;
  groupErrors?: GlobalError;
};

export type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;
export function Label({ children, className, ...props }: LabelProps) {
  return (
    <label
      className={twMerge(
        "text-sm font-semibold leading-[1.37375rem] tracking-[0.1px] text-base-main/70",
        className,
      )}
      {...props}
    >
      {children}
    </label>
  );
}

export type InputProps = WithError<
  React.InputHTMLAttributes<HTMLInputElement> &
    (
      | { leftIcon: React.ReactNode; rightIcon?: undefined }
      | { leftIcon?: undefined; rightIcon: React.ReactNode }
      | { leftIcon?: undefined; rightIcon?: undefined }
    ) & {
      labelName?: string;
      labelClassName?: string;
      labelOnClick?: MouseEventHandler<HTMLLabelElement>;
    }
>;
export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { children, className, error, leftIcon, rightIcon, groupErrors, ...props },
    ref,
  ) => {
    const input = useMemo(
      () => (
        <input
          ref={ref}
          className={twMerge(
            "w-full rounded-lg border py-3",
            leftIcon === undefined && rightIcon === undefined
              ? error || groupErrors
                ? "border-danger-400"
                : "border-base-main"
              : "border-none focus:ring-0",
            className,
          )}
          {...props}
        >
          {children}
        </input>
      ),
      [
        children,
        className,
        error,
        groupErrors,
        props,
        ref,
        leftIcon,
        rightIcon,
      ],
    );

    return (
      <div className="flex-1 flex-col gap-[3px]" tabIndex={-1}>
        {(!!leftIcon || !!rightIcon) && (
          <div className="group flex items-center rounded-lg border border-base-main focus-within:ring-1">
            {leftIcon !== undefined && (
              <div className="grid aspect-square flex-1 shrink-0 place-items-center p-2 pr-0">
                {leftIcon}
              </div>
            )}
            <div className="">{input}</div>
            {rightIcon !== undefined && (
              <div className="grid aspect-square flex-1 shrink-0 place-items-center p-2 pl-0">
                {rightIcon}
              </div>
            )}
          </div>
        )}
        {leftIcon === undefined && rightIcon === undefined && input}
        {error && (
          <p className="text-xs leading-5 tracking-wide text-danger-400">
            {error.message}
          </p>
        )}
      </div>
    );
  },
);

type SelectProps = WithError<React.SelectHTMLAttributes<HTMLSelectElement>>;
export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ className, children, error, ...props }, ref) => {
    return (
      <div className="grid gap-[3px]" tabIndex={-1}>
        <select
          ref={ref}
          style={{
            appearance: "none",
            WebkitAppearance: "none",
            MozAppearance: "none",
            background: `url("${ArrowDropDown}")`,
            backgroundRepeat: "no-repeat no-repeat",
            backgroundPosition: "top 50% right 0.5rem",
            backgroundColor: "#ffffff",
          }}
          className={twMerge(
            `overflow-auto rounded-lg border border-base-main ${
              error ? "border-danger-400" : ""
            }`,
            className,
          )}
          {...props}
        >
          {children}
        </select>
        {error && (
          <p className="text-xs leading-5 text-danger-400">{error.message}</p>
        )}
      </div>
    );
  },
);

export type DivProps = React.HTMLAttributes<HTMLDivElement>;
export function InputGroup({ children, className }: DivProps) {
  return (
    <div className={`grid grid-cols-1 gap-2 ${className || ""}`}>
      {children}
    </div>
  );
}

export const Checkbox = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, id, labelName, labelClassName, labelOnClick, ...props },
    ref,
  ) => {
    return (
      <>
        <input
          type="checkbox"
          id={id ? id : labelName ? kebabCase(labelName) : undefined}
          className={twMerge(
            "aspect-square h-[1.125rem] w-[1.125rem] rounded-sm border-base-main/70 text-primary-100 hover:cursor-pointer",
            className,
          )}
          {...props}
          ref={ref}
        />
        {labelName && (
          <label
            className={twMerge("hover:cursor-pointer", labelClassName)}
            htmlFor={id ? id : labelName ? kebabCase(labelName) : undefined}
            onClick={labelOnClick}
          >
            {labelName}
          </label>
        )}
      </>
    );
  },
);

export const Radio = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, id, labelName, labelClassName, labelOnClick, ...props },
    ref,
  ) => {
    return (
      <>
        <input
          type="radio"
          id={id ? id : labelName ? kebabCase(labelName) : undefined}
          className={twMerge(
            "h-[1.125rem] w-[1.125rem] border-base-main/70 text-primary-100 hover:cursor-pointer",
            className,
          )}
          {...props}
          ref={ref}
        />
        {labelName && (
          <label
            className={twMerge("hover:cursor-pointer", labelClassName)}
            htmlFor={id ? id : labelName ? kebabCase(labelName) : undefined}
            onClick={labelOnClick}
          >
            {labelName}
          </label>
        )}
      </>
    );
  },
);
