import { format } from "date-fns";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import Button from "components/Button";
import { InputGroup, Label, Select } from "components/FormElements";
import { usePopperContainer } from "components/PopperContainer";
import DateRangePicker from "components/datePicker/DateRangePicker";
import { useStore } from "stores";
import { CertificateFilter } from "types/certificate";
import { formatDateRange, naiveDate } from "utils/dates";

type Props = {
  initialData: CertificateFilter;
  onSubmitFilter: (data: Partial<CertificateFilter>) => void;
  clearedData: CertificateFilter;
  isFetching?: boolean;
};

enum Screen {
  START,
  SELECT_LOADING_RANGE,
  SELECT_UPLOADED_RANGE,
}

const CertificateFilterMenu = ({
  onSubmitFilter,
  initialData,
  clearedData,
  isFetching,
}: Props) => {
  const [screen, setScreen] = useState<Screen>(Screen.START);
  const { signalClose } = usePopperContainer();
  const { register, handleSubmit, setValue, watch } =
    useForm<CertificateFilter>({
      defaultValues: {
        certType: initialData.certType,
        supplier: initialData.supplier,
        loadedAt: initialData.loadedAt,
        createdAt: initialData.createdAt,
        createdBy: initialData.createdBy,
      },
    });
  const { certTypeStore, supplierStore, userStore } = useStore();
  const createdAt = watch("createdAt");
  const loadedAt = watch("loadedAt");

  const onSubmit = useCallback(
    (data: CertificateFilter) => {
      onSubmitFilter(data);
      signalClose();
    },
    [onSubmitFilter, signalClose],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex min-w-[80vw] max-w-[80vw] flex-col whitespace-nowrap lg:min-w-[24rem]">
        <div>
          <p className="px-4 pt-2 text-lg font-semibold leading-[1.8rem] text-base-main">
            Filters
          </p>
        </div>
        {screen === Screen.SELECT_LOADING_RANGE && (
          <DateRangePicker
            initialRange={
              initialData.loadedAt?.gte && initialData.loadedAt.lte
                ? {
                    start: naiveDate(initialData.loadedAt?.gte),
                    end: naiveDate(initialData.loadedAt?.lte),
                  }
                : undefined
            }
            onSubmit={(start, end) => {
              setValue("loadedAt.lte", format(end, "yyyy-MM-dd"));
              setValue("loadedAt.gte", format(start, "yyyy-MM-dd"));
              setScreen(Screen.START);
            }}
            onCancel={() => {
              setValue("loadedAt.lte", undefined);
              setValue("loadedAt.gte", undefined);
              setScreen(Screen.START);
            }}
          />
        )}
        {screen === Screen.SELECT_UPLOADED_RANGE && (
          <DateRangePicker
            initialRange={
              initialData.createdAt?.gte && initialData.createdAt.lte
                ? {
                    start: naiveDate(initialData.createdAt?.gte),
                    end: naiveDate(initialData.createdAt?.lte),
                  }
                : undefined
            }
            onSubmit={(start, end) => {
              setValue("createdAt.lte", format(end, "yyyy-MM-dd"));
              setValue("createdAt.gte", format(start, "yyyy-MM-dd"));
              setScreen(Screen.START);
            }}
            onCancel={() => {
              setValue("createdAt.lte", undefined);
              setValue("createdAt.gte", undefined);
              setScreen(Screen.START);
            }}
          />
        )}
        {screen === Screen.START && (
          <>
            <div className="my-7 flex flex-col gap-[0.625rem] px-4">
              <InputGroup>
                <Label className="text-base-main/70">Certificate Type</Label>
                <Select
                  className="py-2"
                  {...register("certType", {
                    required: false,
                  })}
                  defaultValue=""
                >
                  <option value="">All</option>
                  {certTypeStore.typeList.map((certType) => (
                    <option key={certType.id} value={certType.id}>
                      {certType.name}
                    </option>
                  ))}
                </Select>
              </InputGroup>
              <InputGroup>
                <Label className="text-base-main/70">Supplier</Label>
                <Select
                  className="py-2"
                  {...register("supplier", {
                    required: false,
                  })}
                  defaultValue=""
                >
                  <option value="">All</option>
                  {supplierStore.supplierList.map((supplier) => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))}
                </Select>
              </InputGroup>
              <InputGroup>
                <Label className="text-base-main/70">Uploaded by</Label>
                <Select
                  className="py-2"
                  {...register("createdBy", {
                    required: false,
                  })}
                  defaultValue=""
                >
                  <option value="">All</option>
                  {userStore.userList.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.fullName}
                    </option>
                  ))}
                </Select>
              </InputGroup>
              <InputGroup>
                <Label className="text-base-main/70">Loaded At</Label>
                <button
                  type="button"
                  onClick={() => setScreen(Screen.SELECT_LOADING_RANGE)}
                  className="flex items-center justify-between rounded-lg border border-base-main px-3 py-2 text-left"
                >
                  {loadedAt?.lte !== undefined &&
                  loadedAt?.gte !== undefined ? (
                    <span className="text-base-main">
                      {formatDateRange(
                        naiveDate(loadedAt.gte),
                        naiveDate(loadedAt.lte),
                      )}
                    </span>
                  ) : (
                    <span className="text-base-main/[0.35]">Select</span>
                  )}
                  <span className="material-icons-outlined text-base-main/70">
                    calendar_month
                  </span>
                </button>
              </InputGroup>
              <InputGroup>
                <Label className="text-base-main/70">Uploaded At</Label>
                <button
                  type="button"
                  onClick={() => setScreen(Screen.SELECT_UPLOADED_RANGE)}
                  className="flex items-center justify-between rounded-lg border border-base-main px-3 py-2 text-left"
                >
                  {createdAt?.lte !== undefined &&
                  createdAt?.gte !== undefined ? (
                    <span className="text-base-main">
                      {formatDateRange(
                        naiveDate(createdAt.gte),
                        naiveDate(createdAt.lte),
                      )}
                    </span>
                  ) : (
                    <span className="text-base-main/[0.35]">Select</span>
                  )}
                  <span className="material-icons-outlined text-base-main/70">
                    calendar_month
                  </span>
                </button>
              </InputGroup>
            </div>
            <div className="flex flex-row items-center gap-4 px-4 py-2 pb-4">
              <Button
                className="flex-1"
                disabled={isFetching}
                type="reset"
                onClick={() => onSubmit(clearedData)}
                variant="outlined"
              >
                Clear
              </Button>
              <Button type="submit" isLoading={isFetching} className="flex-1">
                Apply filter
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default CertificateFilterMenu;
