import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

import { Radio } from "components/FormElements";
import LoadingCircle from "components/LoadingCircle";
import PreviewCertificateModal from "components/certificates/PreviewCertificateModal";
import ReportCard from "components/reports/ReportCard";
import ReportChart from "components/reports/ReportChart";
import ReportFilterSection from "components/reports/ReportFilterSection";
import useFilter from "hooks/useCoAFilter";
import Certificate from "models/Certificate";
import CoalGrade from "models/CoalGrade";
import { useStore } from "stores";
import { ReportFilter, ReportFilterSchema } from "types/report";
import { getReportColorForId, getReportShapeForId } from "utils/report";

const CLEARED_FILTER_DATA = {
  loadedAt: undefined,
  certType: undefined,
  grade: undefined,
  suppliers: undefined,
};

type ReportDataType = {
  count: number;
  totalWeights: any;
  avgs: Record<string, number>;
  data: any;
  grade: CoalGrade;
  domain: [number, number];
};

const Reports = () => {
  const { updateParams, baseFilter } = useFilter(ReportFilterSchema, [
    "suppliers",
  ]);
  const {
    authStore,
    certificateStore,
    certTypeStore,
    gradeStore,
    supplierStore,
  } = useStore();
  const [reportData, setReportData] = useState<ReportDataType | undefined>(
    undefined,
  );
  const [sizingKey, setSizingKey] = useState<string>("sizing5Less");
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [detailCert, setDetailCert] = useState<Certificate[]>([]);

  const fetchEntities = useCallback(async () => {
    await certTypeStore.fetchAllCertificateTypes(authStore.authHeader);
    await gradeStore.fetchAllCoalGrades(authStore.authHeader);
    await supplierStore.fetchAllSuppliers(authStore.authHeader);
  }, [certTypeStore, gradeStore, supplierStore]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const fetchReportData = useCallback(async () => {
    setIsFetching(true);
    const response = await certificateStore.fetchReport(
      authStore.authHeader,
      baseFilter,
    );

    if (response.ok) {
      setReportData(response.data as any);
    } else {
      setReportData(undefined);
    }
    setIsFetching(false);
  }, [baseFilter]);

  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  const handleSizingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSizingKey(e.target.value);
  };

  const handleOpenCert = async (id: number) => {
    const response = await certificateStore.fetchCertificate(
      authStore.authHeader,
      id,
    );

    if (response.ok && response.data) {
      setOpenDetail(true);
      setDetailCert([response.data as Certificate]);
    }
  };

  return (
    <>
      <div className="sticky top-16 z-[99] w-full bg-contentBg pt-6">
        <h2 className="greeting text-lg font-semibold lg:text-xl">Reports</h2>
        <ReportFilterSection
          initialData={baseFilter as ReportFilter}
          onSubmitFilter={(data: ReportFilter) =>
            updateParams({ ...data, page: undefined })
          }
          clearedData={CLEARED_FILTER_DATA as ReportFilter}
          isFetching={isFetching}
        />
      </div>
      {isFetching && (
        <div className="flex flex-1 items-center justify-center">
          <LoadingCircle size="lg" />
        </div>
      )}
      {reportData && reportData.count > 0 ? (
        <>
          <div className="flex gap-6">
            {reportData.totalWeights.map((supplier: any) => (
              <ReportCard
                key={supplier.id}
                title={supplier.name}
                cardClassName="truncate"
                iconComponent={
                  <div className="material-icons flex items-center justify-end">
                    <span style={{ color: getReportColorForId(supplier.id) }}>
                      {getReportShapeForId(supplier.id) as string}
                    </span>
                  </div>
                }
              >
                <div className="flex items-end gap-2">
                  <div className="text-xl font-semibold text-base-main">
                    {supplier.weight || 0}
                  </div>
                  <div className="font-semibold text-base-main/70">
                    metric tons
                  </div>
                </div>
              </ReportCard>
            ))}
          </div>
          <div className="bg-background p-6">
            <div className="flex justify-between">
              <h2 className="text-lg font-semibold lg:text-xl">Sizing</h2>
              <div className="flex items-center gap-2">
                <Radio
                  id="5-less"
                  labelName="<5mm"
                  name="sizing"
                  value="sizing5Less"
                  checked={sizingKey === "sizing5Less"}
                  onChange={handleSizingChange}
                />
                <Radio
                  id="5-great"
                  labelName=">5mm"
                  name="sizing"
                  value="sizing5"
                  checked={sizingKey === "sizing5"}
                  onChange={handleSizingChange}
                />
                <Radio
                  id="10-great"
                  labelName=">10mm"
                  name="sizing"
                  value="sizing10"
                  checked={sizingKey === "sizing10"}
                  onChange={handleSizingChange}
                />
                <Radio
                  id="30-great"
                  labelName=">30mm"
                  name="sizing"
                  value="sizing30"
                  checked={sizingKey === "sizing30"}
                  onChange={handleSizingChange}
                />
                <Radio
                  id="50-great"
                  labelName=">50mm"
                  name="sizing"
                  value="sizing50"
                  checked={sizingKey === "sizing50"}
                  onChange={handleSizingChange}
                />
                <Radio
                  id="100-great"
                  labelName=">100mm"
                  name="sizing"
                  value="sizing100"
                  checked={sizingKey === "sizing100"}
                  onChange={handleSizingChange}
                />
              </div>
            </div>
            <ReportChart
              data={reportData.data}
              domain={reportData.domain}
              avg={reportData.avgs[sizingKey]}
              xDataKey="loadedAt"
              yDataKey={sizingKey}
              yLabel="Percentage (%)"
              yTicksIsPercentage
              onDotClick={handleOpenCert}
            />
          </div>
          <div className="bg-background p-6">
            <h2 className="text-lg font-semibold lg:text-xl">
              Total Moisture (ARB)
            </h2>
            <ReportChart
              data={reportData.data}
              domain={reportData.domain}
              avg={reportData.avgs.tm}
              min={reportData.grade.minTm}
              max={reportData.grade.maxTm}
              xDataKey="loadedAt"
              yDataKey="tm"
              yLabel="Percentage (%)"
              onDotClick={handleOpenCert}
            />
          </div>
          <div className="bg-background p-6">
            <h2 className="text-lg font-semibold lg:text-xl">Ash (ARB)</h2>
            <ReportChart
              data={reportData.data}
              domain={reportData.domain}
              avg={reportData.avgs.ash}
              min={reportData.grade.minAsh}
              max={reportData.grade.maxAsh}
              xDataKey="loadedAt"
              yDataKey="ash"
              yLabel="Percentage (%)"
              onDotClick={handleOpenCert}
            />
          </div>
          <div className="bg-background p-6">
            <h2 className="text-lg font-semibold lg:text-xl">
              Volatile Matter (ARB)
            </h2>
            <ReportChart
              data={reportData.data}
              domain={reportData.domain}
              avg={reportData.avgs.vm}
              min={reportData.grade.minVm}
              max={reportData.grade.maxVm}
              xDataKey="loadedAt"
              yDataKey="vm"
              yLabel="Percentage (%)"
              onDotClick={handleOpenCert}
            />
          </div>
          <div className="bg-background p-6">
            <h2 className="text-lg font-semibold lg:text-xl">
              Fixed Carbon (ADB)
            </h2>
            <ReportChart
              data={reportData.data}
              domain={reportData.domain}
              avg={reportData.avgs.fc}
              min={reportData.grade.minFc}
              max={reportData.grade.maxFc}
              xDataKey="loadedAt"
              yDataKey="fc"
              yLabel="Percentage (%)"
              onDotClick={handleOpenCert}
            />
          </div>
          <div className="bg-background p-6">
            <h2 className="text-lg font-semibold lg:text-xl">
              Total Sulfur (ARB)
            </h2>
            <ReportChart
              data={reportData.data}
              domain={reportData.domain}
              avg={reportData.avgs.sul}
              min={reportData.grade.minSul}
              max={reportData.grade.maxSul}
              xDataKey="loadedAt"
              yDataKey="sul"
              yLabel="Percentage (%)"
              onDotClick={handleOpenCert}
            />
          </div>
          <div className="bg-background p-6">
            <h2 className="text-lg font-semibold lg:text-xl">GCV (ARB)</h2>
            <ReportChart
              data={reportData.data}
              domain={reportData.domain}
              avg={reportData.avgs.gcv}
              min={reportData.grade.minGcv}
              max={reportData.grade.maxGcv}
              xDataKey="loadedAt"
              yDataKey="gcv"
              yLabel="Kcal/Kg"
              onDotClick={handleOpenCert}
            />
          </div>
          <div className="bg-background p-6">
            <h2 className="text-lg font-semibold lg:text-xl">Net CV (ARB)</h2>
            <ReportChart
              data={reportData.data}
              domain={reportData.domain}
              avg={reportData.avgs.ncv}
              min={reportData.grade.minNcv}
              max={reportData.grade.maxNcv}
              xDataKey="loadedAt"
              yDataKey="ncv"
              yLabel="Kcal/Kg"
              onDotClick={handleOpenCert}
            />
          </div>
        </>
      ) : (
        <div className="flex flex-1 items-center justify-center">
          {!isFetching && (
            <div className="w-96 text-center">
              <p className="material-icons-outlined leading-none text-base-main/70">
                info
              </p>
              <p className="font-semibold">No Reports to Show</p>
              <p className="text-sm text-base-main/70">
                Start by using the filters above.
              </p>
            </div>
          )}
        </div>
      )}
      <PreviewCertificateModal
        open={openDetail}
        onClose={() => setOpenDetail(false)}
        certificates={detailCert}
        onSubmitUpdate={fetchReportData}
      />
    </>
  );
};

export default observer(Reports);
