import { observer } from "mobx-react-lite";
import { FC } from "react";

import Button from "components/Button";
import Modal from "components/Modal";

type ConfirmModalProps = {
  title?: string;
  subtitle?: string;
  message?: string;
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
};

const ConfirmModal: FC<ConfirmModalProps> = ({
  title = "Are you sure you want to cancel?",
  subtitle = "",
  message = "",
  open = false,
  onClose = () => {},
  onConfirm = () => {},
}) => {
  return (
    <>
      <Modal
        title={title}
        subtitle={subtitle}
        open={open}
        onClose={onClose}
        className="w-1/5"
      >
        <div className="flex h-24 min-h-full flex-col justify-between gap-2">
          <p>{message}</p>
          <div className="flex gap-2 border-t border-gray-200">
            <Button fullWidth onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button fullWidth onClick={onConfirm} variant="contained">
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default observer(ConfirmModal);
