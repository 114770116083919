import { Checkbox } from "components/FormElements";
import Supplier from "models/Supplier";

type ReportSupplierDropdownProps = {
  supplierList: Supplier[];
  selectedList: number[];
  onChange: (id: number | undefined) => void;
};

export default function ReportSupplierDropdown({
  supplierList,
  selectedList,
  onChange,
}: ReportSupplierDropdownProps) {
  return (
    <div className="rounded-md bg-background">
      <ul className="w-ful max-h-[300px]">
        {supplierList.map((supplier) => (
          <li
            key={supplier.id}
            className="block w-full border border-transparent [&:is(.Mui-focusVisible)]:border [&:is(.Mui-focusVisible)]:bg-primary-bg"
          >
            <button
              className="flex h-full items-center gap-[0.625rem] px-4 py-2 text-left hover:bg-primary-bg"
              type="button"
              tabIndex={-1}
              onClick={() => onChange(supplier.id)}
            >
              <div className="p-2 text-primary-100">
                <Checkbox
                  className="border-2 border-base-main/[0.86]"
                  checked={selectedList.indexOf(supplier.id) > -1}
                  readOnly
                />
              </div>
              {supplier.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
