import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import {
  CartesianGrid,
  Label,
  ReferenceLine,
  ReferenceArea,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";

import { getReportColorForId, getReportShapeForId } from "utils/report";

const dateFormatter = (date: number) => {
  return format(new Date(date), "MMMM dd");
};

const getDateTicks = function (start: number, end: number) {
  const arr = [];
  const dateDiff = Math.floor((end - start) / (1000 * 60 * 60 * 24));
  const dateInterval = Math.ceil(dateDiff / 7);

  for (
    const dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + dateInterval)
  ) {
    const ndt = new Date(dt);

    arr.push(ndt.getTime());
  }
  return arr;
};

const getYTicks = function (isPercentage: boolean, min?: number, max?: number) {
  if (isPercentage) {
    return [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  }
  const ticks = [];

  if (typeof min === "number") ticks.push(parseInt(min.toString(), 10));
  if (typeof max === "number") ticks.push(parseInt(max.toString(), 10));
  return ticks;
};

// const getYDomain = function (min: number, max: number) {
//   let minValue = 0;
//   let maxValue = 100;

//   if (min) minValue = min - (max - min) * 0.2;
//   if (max) maxValue = max + (max - min) * 0.2;

//   return [minValue, maxValue];
// };

const getUpdatedData = function (data: any, dataKey: string) {
  const updatedData = data.map((d: any) => {
    const dateValue = new Date(d[dataKey]);

    if (!isNaN(dateValue.getTime())) {
      return {
        x: dateValue.getTime(),
        ...d,
      };
    }
    return {
      x: d[dataKey],
      ...d,
    };
  });

  return updatedData;
};

type ChartProps = {
  data: any;
  domain: [number, number];
  avg?: number;
  min?: number;
  max?: number;
  xDataKey: string;
  xLabel?: string;
  yDataKey: string;
  yLabel?: string;
  yTicksIsPercentage?: boolean;
  onDotClick?: (id: number) => void;
};

const ReportChart = ({
  data,
  domain,
  avg,
  min = 0,
  max = 100,
  xDataKey,
  xLabel,
  yDataKey,
  yLabel,
  yTicksIsPercentage = false,
  onDotClick,
}: ChartProps) => {
  const handleClick = (payload: any) => {
    if (onDotClick) {
      onDotClick(payload.id);
    }
  };

  console.log("min, max, y data", min, max, data);

  return (
    <div className="pt-4">
      <ResponsiveContainer width="100%" height={440}>
        <ScatterChart width={730} height={250}>
          <CartesianGrid stroke="#D7D7D754" vertical={false} />
          <XAxis
            domain={domain}
            scale="time"
            ticks={getDateTicks(domain[0], domain[1])}
            tickLine={false}
            type="number"
            dataKey="x"
            tickFormatter={dateFormatter}
          >
            {xLabel && (
              <Label value={xLabel} offset={0} position="insideBottom" />
            )}
          </XAxis>
          <YAxis
            tickLine={false}
            dataKey={yDataKey}
            ticks={getYTicks(yTicksIsPercentage, min, max)}
          >
            {yLabel && (
              <Label angle={-90} value={yLabel} position="insideLeft" />
            )}
          </YAxis>
          {avg && (
            <ReferenceLine
              y={avg}
              label={{ value: `AVG: ${avg}`, position: "center" }}
              strokeDasharray="10 10"
              stroke="#00000054"
            />
          )}
          {min && <ReferenceArea y2={min} fill="#CC29321A" stroke="#CE1A1D" />}
          {max && <ReferenceArea y1={max} fill="#CC29321A" stroke="#CE1A1D" />}
          <Tooltip
            content={<CustomTooltip />}
            formatter={(value) =>
              `₱ ${value.toLocaleString("en-us", {
                minimumFractionDigits: 2,
              })}`
            }
          />
          {data.map((item: any) => (
            <Scatter
              key={item.id}
              data={getUpdatedData(item.results, xDataKey)}
              name={item.name}
              fill={getReportColorForId(item.id)}
              shape={getReportShapeForId(item.id)}
              onClick={handleClick}
            />
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default observer(ReportChart);

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const dateDisplay = payload[0].value
      ? format(payload[0].value as number, "MMMM dd")
      : "";

    return (
      <div className="custom-tooltip pointer-events-auto w-[320px] rounded-md bg-background p-4 shadow-card">
        <div className="grid grid-cols-2 gap-4 font-montserrat">
          <div className="uppercase">{payload[1].dataKey}:</div>
          <div className="font-semibold">{payload[1].value}</div>
        </div>
        <div className="grid grid-cols-2 gap-4 font-montserrat">
          <div className="uppercase">Loading Date:</div>
          <div className="font-semibold">{dateDisplay}</div>
        </div>
      </div>
    );
  }

  return null;
};
