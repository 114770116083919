import { useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

import { ToastProps } from "./context";

const TOAST_CONTAINER_CLASS =
  "fixed top-[4.375rem] z-[1000] flex flex-col items-center w-full";

const TOAST_CLASS_NAME =
  "shadow-toast w-[calc(100%-48px)] rounded-lg bg-white px-5 py-2 lg:top-[5.125rem] lg:w-full lg:max-w-[37.5rem] lg:rounded-2xl lg:p-5";

const Toast = ({
  isPersistent = undefined,
  useToastFunc,
  timeoutDuration,
  parentClassName,
  ...props
}: ToastProps) => {
  const { isToastShown, setIsToastShown } = useToastFunc();

  const [hasFinishedAnimation, setHasFinishedAnimation] = useState(false);

  useEffect(() => {
    if (!isToastShown || isPersistent) {
      return;
    }

    setHasFinishedAnimation(false);

    const timeout = setTimeout(() => {
      setHasFinishedAnimation(true);
    }, timeoutDuration || 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isToastShown, timeoutDuration, isPersistent]);

  useEffect(() => {
    if (!hasFinishedAnimation) {
      return;
    }

    const timeout = setTimeout(() => setIsToastShown(false), 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [hasFinishedAnimation]);

  const animationClasses = useMemo(() => {
    if (isToastShown && !hasFinishedAnimation) {
      return "animate-[toastSlideDown_0.2s_cubic-bezier(0.33,_1,_0.68,_1)_forwards]";
    }
    return "animate-[toastSlideUp_0.2s_cubic-bezier(0.33,_1,_0.68,_1)_forwards]";
  }, [isToastShown, hasFinishedAnimation]);

  return isToastShown || hasFinishedAnimation ? (
    <div className={TOAST_CONTAINER_CLASS}>
      <div
        className={twMerge(
          TOAST_CLASS_NAME,
          animationClasses,
          parentClassName || "",
        )}
      >
        {props.simpleMessage && <p>{props.simpleMessage}</p>}
        {props.children}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Toast;
