import { FC } from "react";
import { twMerge } from "tailwind-merge";

import Button from "components/Button";
import ToolTip from "components/Tooltip";

type SelectedFileProps = {
  file: File;
  onDelete: () => void;
  hasError?: boolean;
  hasAPIError?: boolean;
};

const SelectedFile: FC<SelectedFileProps> = ({
  file,
  onDelete,
  hasError = false,
  hasAPIError = false,
}) => {
  const baseClasses =
    "flex flex-1 flex-col items-center justify-center rounded border border-gray-300";
  const errorClasses = hasError || hasAPIError ? "border-red-500" : "";

  return (
    <div className={twMerge(baseClasses, errorClasses)}>
      <div className="flex w-full items-center justify-between gap-4 px-4 py-3">
        <div className="flex items-center justify-between gap-2">
          {hasError && (
            <p className="text-sm font-bold text-red-700">Duplicate</p>
          )}
          {hasAPIError && (
            <>
              <p className="text-sm font-bold text-red-700">Duplicate</p>
              <ToolTip
                titleComponent={
                  <div className="flex flex-col items-center overflow-hidden">
                    <div
                      id="arrow"
                      data-popper-arrow
                      className="text-base-main"
                    />
                    <div className="overflow-hidden rounded-lg bg-background p-3 text-sm">
                      <span className="font-bold leading-[166%]">
                        This file has already been uploaded today
                      </span>
                    </div>
                  </div>
                }
                popperClassName="shadow-tooltip drop-shadow-tooltip rounded-lg"
              >
                <span className="material-icons-outlined text-sm text-red-700">
                  info
                </span>
              </ToolTip>
            </>
          )}
          <p className=" text-gray-500">{file.name}</p>
        </div>

        <Button onClick={onDelete} variant="icon" iconName="close" />
      </div>
    </div>
  );
};

export default SelectedFile;
