import { computed } from "mobx";
import {
  Model,
  ModelCreationData,
  _async,
  _await,
  arrayToMapTransform,
  model,
  modelFlow,
  prop,
} from "mobx-keystone";

import Supplier from "models/Supplier";
import { AuthHeader } from "types/auth";
import { ErrorMessage, API_ERROR_MESSAGES, APIError } from "types/error";
import { ID, PageResponse } from "types/pageResponse";
import fetchAPI from "utils/fetchAPI";

type SupplierId = ID<Supplier>;

@model("web/SupplierStore")
export default class SupplierStore extends Model({
  suppliers: prop<[SupplierId, Supplier][]>(() => [])
    .withTransform(arrayToMapTransform())
    .withSetter(),
  isReady: prop<boolean>(true).withSetter(),
  errors: prop<ErrorMessage[]>(() => []).withSetter(),
}) {
  @computed
  get supplierList(): Supplier[] {
    return Array.from(this.suppliers.values());
  }

  @modelFlow
  fetchAllSuppliers = _async(function* (
    this: SupplierStore,
    authHeader: AuthHeader,
    grade?: number,
  ) {
    let response: Response;
    let params = "";

    if (grade) params = `?grades=${grade}`;
    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI(`api/coal/suppliers/${params}`, {
          headers: {
            ...authHeader,
          },
        }),
      );
    } catch {
      this.errors.push({
        source: "fetchAllSuppliers",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return {
        error: "Failed to fetch",
        results: [],
      } as PageResponse<Supplier>;
    }

    if (!response.ok) {
      return {
        error: "Failed to fetch",
        results: [],
      } as PageResponse<Supplier>;
    }
    const data = (yield* _await(
      response.json(),
    )) as ModelCreationData<Supplier>[];

    data.forEach((supplier) =>
      this.suppliers.set(supplier.id, new Supplier({ ...supplier })),
    );
    this.isReady = true;
    return {
      results: data,
    } as PageResponse<Supplier>;
  });

  @modelFlow
  fetchSupplier = _async(function* (
    this: SupplierStore,
    authHeader: AuthHeader,
    id: number,
  ) {
    let response: Response;

    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI(`api/coal/suppliers/${id}`, {
          headers: {
            ...authHeader,
          },
        }),
      );
    } catch {
      this.errors.push({
        source: "fetchSupplier",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }
    const supplier = (yield* _await(
      response.json(),
    )) as ModelCreationData<Supplier>;

    this.suppliers.set(supplier.id, new Supplier({ ...supplier }));
    this.isReady = true;
    return { details: "Successfully fetched", ok: true };
  });
}
