/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";

import Button from "components/Button";
import Modal from "components/Modal";
import TextInput from "components/TextInput";
import { useToast } from "components/toast/context";
import Certificate, { CertificateData } from "models/Certificate";
import { useStore } from "stores";

import PDFViewer from "./PDFViewer";

type PreviewCertificateModalProps = {
  open: boolean;
  onClose?: () => void;
  certificates: Certificate[];
  onSubmitUpdate?: () => void;
};

type PreviewCertificateFormInputs = {
  general_info: CertificateData[];
  sizing: CertificateData[];
  proximate_analysis: CertificateData[];
  ultimate_analysis: CertificateData[];
  aft: CertificateData[];
  isConfirmed: boolean;
};

const snakeToCamel = (str: string) =>
  str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

const PreviewCertificateModal: FC<PreviewCertificateModalProps> = ({
  open = false,
  onClose = () => {},
  onSubmitUpdate = () => {},
  certificates,
}) => {
  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    // formState: { errors },
  } = useForm<PreviewCertificateFormInputs>({
    defaultValues: {
      general_info: [],
      sizing: [],
      proximate_analysis: [],
      ultimate_analysis: [],
      aft: [],
    },
  });

  // console.log("preview certs:", certificates);

  const generalInfoArray = useFieldArray({ control, name: "general_info" });
  const sizingArray = useFieldArray({ control, name: "sizing" });
  const proximateAnalysisArray = useFieldArray({
    control,
    name: "proximate_analysis",
  });
  const ultimateAnalysisArray = useFieldArray({
    control,
    name: "ultimate_analysis",
  });
  const aftArray = useFieldArray({ control, name: "aft" });

  const fieldArrays = {
    general_info: generalInfoArray,
    sizing: sizingArray,
    proximate_analysis: proximateAnalysisArray,
    ultimate_analysis: ultimateAnalysisArray,
    aft: aftArray,
  };

  const fieldArrayData = useMemo(() => {
    const certificateData = certificates[0]?.data ?? [];

    const arrays = Object.keys(fieldArrays).reduce((acc, key) => {
      const arrayData = certificateData.filter((data) => data.category === key);

      acc[key] = arrayData;
      return acc;
    }, {} as Record<string, CertificateData[]>);

    return arrays;
  }, [certificates]);

  // Categories
  type Field =
    | "label"
    | "units"
    | "value"
    | "asReceived"
    | "asDetermined"
    | "dryBasis"
    | "reducing"
    | "oxidizing";

  type Category = {
    key:
      | "general_info"
      | "sizing"
      | "proximate_analysis"
      | "ultimate_analysis"
      | "aft";
    label: string;
    fields: Field[];
  };

  const FIELD_CATEGORIES: Category[] = [
    { key: "general_info", label: "General Info", fields: ["value"] },
    { key: "sizing", label: "Sizing", fields: ["units", "value"] },
    {
      key: "proximate_analysis",
      label: "Proximate Analysis",
      fields: ["units", "asReceived", "asDetermined", "dryBasis"],
    },
    {
      key: "ultimate_analysis",
      label: "Ultimate Analysis",
      fields: ["units", "asReceived", "asDetermined", "dryBasis"],
    },
    {
      key: "aft",
      label: "Ash Fusion Temperatures",
      fields: ["units", "reducing", "oxidizing"],
    },
  ];

  const FIELD_LABELS = {
    label: "Label",
    units: "Units",
    value: "Value",
    asReceived: "As Received Basis",
    asDetermined: "As Determined Basis",
    dryBasis: "Dry Basis",
    reducing: "Reducing",
    oxidizing: "Oxidizing",
  };

  // SETTING VALUES FOR FORM FROM DATA PROVIDED
  useEffect(() => {
    if (open) {
      reset(fieldArrayData);
    }
  }, [open, fieldArrayData]);

  const { certificateStore, authStore } = useStore();
  const { showToastVariant } = useToast();

  const handleClose = () => {
    onClose();
  };

  const onSubmit: SubmitHandler<PreviewCertificateFormInputs> = async (
    formData,
  ) => {
    console.log("data submitted:", formData);

    const finalData = [
      ...formData.general_info,
      ...formData.sizing,
      ...formData.proximate_analysis,
      ...formData.ultimate_analysis,
      ...formData.aft,
    ];

    console.log("final data:", finalData);
    const response = await certificateStore.updateCertificate(
      authStore.authHeader,
      certificates[0].id,
      {
        data: finalData,
        isConfirmed: true,
      },
    );

    if (response.ok) {
      showToastVariant({
        variant: "success",
        title: "Success",
        subtitle: "Successfully updated certificate",
      });
      onSubmitUpdate();
      handleClose();
    } else {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: response.details as string,
      });
    }
  };

  const renderFields = useCallback(
    (category: Category, items: CertificateData[]) => {
      const { fields, append, remove } = fieldArrays[category.key];

      return (
        <div className="flex flex-col gap-1">
          <p className="mb-1 mt-4 font-semibold">{category.label}</p>
          {fields?.map((field, i) => {
            const label = getValues(`${category.key}.${i}.label`);
            // todo: move deviations detection to CertificateData model?
            const error = snakeToCamel(
              certificates[0].deviations.find(
                (deviation) => deviation.label === label,
              )?.field ?? "",
            );

            return (
              <div key={field.id} className="flex gap-1">
                <TextInput
                  {...register(`${category.key}.${i}.label`, {
                    required: true,
                  })}
                  title={i === 0 ? "Label" : ""}
                  autoComplete="new-password"
                  fullWidth
                  divClassName="w-3/12"
                />
                {category.fields.map((field) => (
                  <TextInput
                    {...register(`${category.key}.${i}.${field}`)}
                    title={i === 0 ? FIELD_LABELS[field] : ""}
                    type={
                      ["asReceived", "asDetermined", "dryBasis"].includes(field)
                        ? "number"
                        : undefined
                    }
                    step={0.001}
                    fullWidth
                    divClassName="w-2/12"
                    hasError={error === field}
                  />
                ))}

                <Button
                  onClick={() => remove(i)}
                  variant="icon"
                  iconName="close"
                />
              </div>
            );
          })}
          <div>
            <Button
              variant="outlined"
              onClick={() =>
                append({
                  category: category.key,
                  label: "",
                  value: "",
                } as CertificateData)
              }
              iconName="add_circle_outline"
            >
              Add
            </Button>
          </div>
        </div>
      );
    },
    [fieldArrayData, fieldArrays],
  );

  const subtitle = `${certificates[0]?.name}${
    certificates[0]?.deviations.length > 0 ? ": Deviations detected" : ""
  }`;

  return (
    <Modal
      title="Preview of Certficate of Analysis"
      subtitle={subtitle}
      className="w-5/6 min-w-[600px] max-w-[1920px]"
      open={open}
      onClose={handleClose}
    >
      <div className="flex w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full gap-2">
              <div className="flex max-h-[60vh] w-4/5 flex-col gap-1 overflow-y-auto">
                {FIELD_CATEGORIES.map((category) => {
                  return renderFields(category, fieldArrayData[category.key]);
                })}
              </div>
              <PDFViewer pdfUrl={certificates[0]?.file} />
            </div>
          </div>
          <hr className="my-2" />
          <div className="mt-2 flex w-full justify-end gap-2 border-t border-gray-200">
            <Button fullWidth onClick={onClose} variant="outlined">
              Back
            </Button>
            <Button fullWidth type="submit" variant="contained">
              Confirm File Details
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default observer(PreviewCertificateModal);
