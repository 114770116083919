import { computed } from "mobx";
import {
  Model,
  ModelCreationData,
  _async,
  _await,
  arrayToMapTransform,
  model,
  modelFlow,
  prop,
} from "mobx-keystone";

import Certificate from "models/Certificate";
import { AuthHeader } from "types/auth";
import { CertificateFilter } from "types/certificate";
import { ErrorMessage, API_ERROR_MESSAGES, APIError } from "types/error";
import { ID, Page, PageResponse } from "types/pageResponse";
import { ReportFilter } from "types/report";
import fetchAPI from "utils/fetchAPI";
import { convertFilterToParams } from "utils/filtering";

type CertificateId = ID<Certificate>;

type CertificateUploadResponse = {
  certificates: Certificate[];
};

@model("web/CertificateStore")
export default class CertificateStore extends Model({
  certificates: prop<[CertificateId, Certificate][]>(() => [])
    .withTransform(arrayToMapTransform())
    .withSetter(),
  isReady: prop<boolean>(true).withSetter(),
  errors: prop<ErrorMessage[]>(() => []).withSetter(),
}) {
  @computed
  get certificateList(): Certificate[] {
    return Array.from(this.certificates.values());
  }

  @modelFlow
  fetchAllCertificates = _async(function* (
    this: CertificateStore,
    authHeader: AuthHeader,
    baseFilter: CertificateFilter,
    searchParams?: URLSearchParams,
  ) {
    const mergedQueryParam = convertFilterToParams(baseFilter);

    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        if (!mergedQueryParam.has(key)) {
          mergedQueryParam.set(key, value);
        }
      }
    }

    const url = `api/coal/certificates/?${mergedQueryParam.toString()}`;
    let response: Response;

    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI(url, {
          headers: {
            ...authHeader,
          },
        }),
      );
    } catch {
      this.errors.push({
        source: "fetchAllCertificates",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return {
        error: "Failed to fetch",
        results: [],
      } as PageResponse<Certificate>;
    }

    if (!response.ok) {
      return {
        error: "Failed to fetch",
        results: [],
      } as PageResponse<Certificate>;
    }
    const data = (yield* _await(response.json())) as Page<
      ModelCreationData<Certificate>
    >;

    data.results.forEach((certificate) =>
      this.certificates.set(
        certificate.id,
        new Certificate({ ...certificate }),
      ),
    );
    this.isReady = true;

    return data as PageResponse<Certificate>;
  });

  @modelFlow
  fetchCertificate = _async(function* (
    this: CertificateStore,
    authHeader: AuthHeader,
    id: number,
  ) {
    let response: Response;

    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI(`api/coal/certificates/${id}`, {
          headers: {
            ...authHeader,
          },
        }),
      );
    } catch {
      this.errors.push({
        source: "fetchCertificate",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to fetch", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to fetch", ok: false };
    }
    const certificate = (yield* _await(
      response.json(),
    )) as ModelCreationData<Certificate>;

    this.certificates.set(certificate.id, new Certificate({ ...certificate }));
    this.isReady = true;
    return { details: "Successfully fetched", ok: true, data: certificate };
  });

  @modelFlow
  updateCertificate = _async(function* (
    this: CertificateStore,
    authHeader: AuthHeader,
    id: number,
    updatedData: Partial<Certificate>,
  ) {
    let response: Response;

    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI(`api/coal/certificates/${id}/`, {
          method: "PATCH",
          headers: {
            ...authHeader,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }),
      );
    } catch {
      this.errors.push({
        source: "updateCertificate",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Failed to update", ok: false };
    }

    if (!response.ok) {
      return { details: "Failed to update", ok: false };
    }

    const updatedCertificate = (yield* _await(
      response.json(),
    )) as ModelCreationData<Certificate>;

    this.certificates.set(
      updatedCertificate.id,
      new Certificate({ ...updatedCertificate }),
    );
    this.isReady = true;
    return { details: "Successfully updated", ok: true };
  });

  @modelFlow
  uploadCertificates = _async(function* (
    this: CertificateStore,
    authHeader: AuthHeader,
    certificateData: any,
  ) {
    try {
      const formData = new FormData();

      // Append certificateData fields to FormData
      for (const key in certificateData) {
        if (certificateData.hasOwnProperty(key)) {
          if (key === "files" && Array.isArray(certificateData[key])) {
            // Handle files array separately
            certificateData[key].forEach((file: File, index: number) => {
              formData.append("files", file);
            });
          } else {
            // Append other fields normally
            formData.append(key, certificateData[key]);
          }
        }
      }

      const response = yield* _await(
        fetchAPI("api/coal/certificates/upload/", {
          method: "POST",
          headers: {
            ...authHeader,
          },
          body: formData,
        }),
      );

      if (!response.ok) {
        throw new Error("Failed to create certificate");
      }

      const data = (yield* _await(
        response.json(),
      )) as CertificateUploadResponse;

      console.log("certificates upload response:", data);
      const newIds: number[] = [];

      data.certificates.forEach((cert) => {
        this.certificates.set(cert.id, new Certificate({ ...cert }));
        newIds.push(cert.id);
      });
      console.log("current certs:", this.certificates);

      return {
        ids: newIds,
        details: "Certificate created successfully",
        ok: true,
      };
    } catch (error) {
      console.error("Failed to create certificate:", error);
      this.errors.push({
        source: "createCertificate",
        title: "Certificate creation failed",
        message: "There was an error uploading the certificates",
      });
      return {
        ids: [],
        details: "Error uploading certificates",
        ok: false,
      };
    }
  });

  @modelFlow
  confirmCertificates = _async(function* (
    this: CertificateStore,
    authHeader: AuthHeader,
    ids: number[],
  ) {
    try {
      const response = yield* _await(
        fetchAPI("api/coal/certificates/confirm/", {
          method: "POST",
          headers: {
            ...authHeader,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ids }),
        }),
      );

      if (!response.ok) {
        throw new Error("Failed to confirm certificate");
      }

      const data = (yield* _await(
        response.json(),
      )) as CertificateUploadResponse;

      console.log("certificates confirm response:", data);
      const newIds: number[] = [];

      data.certificates.forEach((cert) => {
        this.certificates.set(cert.id, new Certificate({ ...cert }));
        newIds.push(cert.id);
      });
      console.log("current certs:", this.certificates);

      return {
        ids: newIds,
        details: "Certificates successfully confirmed",
        ok: true,
      };
    } catch (error) {
      console.error("Failed to confirm certificates:", error);
      this.errors.push({
        source: "confirmCertificates",
        title: "Certificate confirmation failed",
        message: "There was an error confirming the certificates",
      });
      return {
        ids: [],
        details: "Error confirming certificates",
        ok: false,
      };
    }
  });

  @modelFlow
  deleteCertificates = _async(function* (
    this: CertificateStore,
    authHeader: AuthHeader,
    ids: number[],
  ) {
    try {
      const response = yield* _await(
        fetchAPI("api/coal/certificates/delete/", {
          method: "POST",
          headers: {
            ...authHeader,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ids }),
        }),
      );

      if (!response.ok) {
        throw new Error("Failed to delete certificates");
      }

      return {
        details: "Certificates successfully deleted",
        ok: true,
      };
    } catch (error) {
      console.error("Failed to delete certificates:", error);
      this.errors.push({
        source: "deleteCertificates",
        title: "Certificate deletion failed",
        message: "There was an error deleting the certificates",
      });
      return {
        details: "Error deleting certificates",
        ok: false,
      };
    }
  });

  @modelFlow
  fetchReport = _async(function* (
    this: CertificateStore,
    authHeader: AuthHeader,
    baseFilter: ReportFilter,
    searchParams?: URLSearchParams,
  ) {
    const mergedQueryParam = convertFilterToParams(baseFilter);

    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        if (!mergedQueryParam.has(key)) {
          mergedQueryParam.set(key, value);
        }
      }
    }

    const url = `api/coal/report/?${mergedQueryParam.toString()}`;
    let response: Response;

    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI(url, {
          headers: {
            ...authHeader,
          },
        }),
      );
    } catch {
      this.errors.push({
        source: "fetchReport",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return {
        error: "Failed to fetch",
        ok: false,
        data: undefined,
      };
    }

    if (!response.ok) {
      return {
        error: "Failed to fetch",
        ok: false,
        data: undefined,
      };
    }

    const data = yield* _await(response.json());

    this.isReady = true;
    return { error: "", ok: true, data };
  });

  @modelFlow
  checkNameDuplicate = _async(function* (
    this: CertificateStore,
    authHeader: AuthHeader,
    name: string,
  ) {
    const url = `api/coal/certificates/name-check?name=${name}`;
    let response: Response;

    this.isReady = false;
    try {
      response = yield* _await(
        fetchAPI(url, {
          headers: {
            ...authHeader,
          },
        }),
      );
    } catch {
      this.errors.push({
        source: "checkNameDuplicate",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return false;
    }

    if (!response.ok) {
      return false;
    }
    const data = yield* _await(response.json());

    return data.ok;
  });
}
