import { twMerge } from "tailwind-merge";

import { useToast } from "./context";

export type ToastVariant =
  | "success"
  | "error"
  | "warning"
  | "information"
  | "basic";

export const TOAST_BACKGROUND_COLORS: Record<ToastVariant, string> = {
  success: "bg-success-light",
  error: "bg-error-light",
  warning: "bg-warning-light",
  information: "bg-info-light",
  basic: "bg-basic-primary",
};

export const TOAST_ICON_COLORS: Record<ToastVariant, string> = {
  success: "text-success-light",
  error: "text-error-light",
  warning: "text-warning-light",
  information: "text-info-light",
  basic: "text-basic-primary",
};

export const TOAST_ICON_BACKGROUND_COLORS: Record<ToastVariant, string> = {
  success: "bg-success-main",
  error: "bg-error-main",
  warning: "bg-warning-main",
  information: "bg-info-main",
  basic: "bg-primary-900",
};

export const TOAST_ICONS: Record<ToastVariant, string> = {
  success: "check_circle",
  error: "cancel",
  warning: "warning_amber",
  information: "info",
  basic: "notification_important",
};

export type VariantButtonHandler = ({
  handleClose,
}: {
  handleClose: () => void;
}) => React.ReactNode;

type ToastContentProps = {
  variant: ToastVariant;
  title: string;
  subtitle?: string;
  subtitleComponent?: React.ReactNode;
  parentClassName?: string;
  button?: VariantButtonHandler;
};

const ToastContents = ({
  variant,
  title,
  subtitle,
  subtitleComponent = undefined,
  button,
}: ToastContentProps) => {
  const { setIsToastShown } = useToast();

  return (
    <div
      className={twMerge(
        "flex items-center justify-between",
        !subtitle && "py-1",
      )}
    >
      <div className="flex w-full items-center gap-4">
        <div
          className={twMerge(
            "material-icons-outlined grid aspect-square w-5 place-items-center rounded-full text-xs lg:w-10 lg:text-2xl ",
            TOAST_ICON_COLORS[variant],
            TOAST_ICON_BACKGROUND_COLORS[variant],
          )}
        >
          {TOAST_ICONS[variant]}
        </div>
        <div className="flex w-full flex-1 flex-col">
          <p className="text-[13px] font-semibold leading-[22.1px] text-toast-text lg:text-lg">
            {title}
          </p>
          {subtitleComponent
            ? subtitleComponent
            : !!subtitle && (
                <p className="line-clamp-2 max-w-[48ch] text-xs leading-[19.92px] tracking-[0.4px] text-toast-text/70 lg:text-base lg:leading-7">
                  {subtitle}
                </p>
              )}
        </div>
        {!!button && (
          <div>{button({ handleClose: () => setIsToastShown(false) })}</div>
        )}
      </div>
    </div>
  );
};

export default ToastContents;
