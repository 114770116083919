import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { Page } from "types/pageResponse";

import TableSkeleton from "./Skeleton";

export type Props<T> = React.TableHTMLAttributes<HTMLTableElement> & {
  headerTitles: string[];
  currentPage?: Page<T>;
  renderRow: (data: T) => React.ReactElement;
  handlePageMove: (page: number) => void;
  areAllItemsInPage?: boolean;
  alignLastColToRight?: boolean;
  columns?: { name: string; fieldName: string }[];
  canShowAllRows?: boolean;
  showLoadingSkeleton?: boolean;
};

export default function PaginatedTable<T>({
  headerTitles,
  currentPage,
  renderRow,
  handlePageMove,
  areAllItemsInPage,
  alignLastColToRight = false,
  columns = [],
  canShowAllRows,
  showLoadingSkeleton,
  ...props
}: Props<T>) {
  const [searchParams] = useSearchParams();

  const page = useMemo(
    () => parseInt(searchParams.get("page") || "1", 10),
    [searchParams],
  );

  const navigatePage = useCallback(
    (isNext: boolean) => {
      const next = isNext ? 1 : -1;

      handlePageMove(page + next);
    },
    [handlePageMove, page],
  );

  if (showLoadingSkeleton) {
    return <TableSkeleton />;
  }

  return (
    <table {...props}>
      <thead>
        <tr
          className={twMerge(
            "border-y text-left text-sm font-semibold",
            alignLastColToRight ? "[&>:last-child]:text-right" : "",
          )}
        >
          {headerTitles.map((title, idx) => (
            <th key={title}>
              <div
                className={twMerge(
                  "flex items-stretch gap-2",
                  alignLastColToRight && idx === headerTitles.length - 1
                    ? "justify-end"
                    : "justify-between",
                )}
              >
                <div>
                  <div className="p-4">{title}</div>
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="[&>]:border-x [&>tr:not(:last-child)]:border-b">
        {currentPage?.count !== undefined &&
          currentPage.count > 0 &&
          currentPage?.results.map((item) => renderRow(item))}
        <tr>
          <td colSpan={100}>
            <div className="flex items-center justify-end text-sm">
              <div className="flex items-center">
                {currentPage?.count !== undefined && currentPage.count > 0 && (
                  <p className="pl-2 text-xs leading-5 lg:text-sm">
                    {currentPage.startIdx}-{currentPage.endIdx} of{" "}
                    {currentPage.count}
                  </p>
                )}
                <button
                  disabled={!currentPage?.previous}
                  onClick={() => navigatePage(false)}
                  className="material-icons disabled:text-dark-900 p-[0.75rem] tracking-tighter text-primary-900 disabled:opacity-70"
                >
                  chevron_left
                </button>
                <button
                  disabled={!currentPage?.next}
                  onClick={() => navigatePage(true)}
                  className="material-icons disabled:text-dark-900 p-[0.75rem] tracking-tighter text-primary-900 disabled:opacity-70"
                >
                  chevron_right
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

type TableProps<T> = {
  headerTitles: string[];
  renderRow: (item: T) => React.ReactNode;
  alignLastColToRight?: boolean;
  showLoadingSkeleton?: boolean;
  items: T[];
} & React.TableHTMLAttributes<HTMLTableElement>;

export function Table<T>({
  headerTitles,
  renderRow,
  alignLastColToRight = false,
  showLoadingSkeleton,
  items,
  ...props
}: TableProps<T>) {
  if (showLoadingSkeleton) {
    return <TableSkeleton />;
  }

  return (
    <table {...props}>
      <thead>
        <tr
          className={twMerge(
            "border-y text-left text-sm font-semibold",
            alignLastColToRight ? "[&>:last-child]:text-right" : "",
          )}
        >
          {headerTitles.map((title, idx) => (
            <th key={title}>
              <div
                className={twMerge(
                  "flex items-stretch gap-2",
                  alignLastColToRight && idx === headerTitles.length - 1
                    ? "justify-end"
                    : "justify-between",
                )}
              >
                <div>
                  <div className="p-4">{title}</div>
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="[&>]:border-x [&>tr:not(:last-child)]:border-b">
        {items.length > 0 && items.map((item) => renderRow(item))}
      </tbody>
    </table>
  );
}
