import { z } from "zod";

export const ReportFilterSchema = z
  .object({
    loadedAt: z
      .object({
        lte: z.string(),
        gte: z.string(),
      })
      .partial(),
    certType: z.coerce.number(),
    grade: z.coerce.number(),
    suppliers: z.array(z.coerce.number()),
    search: z.string(),
    page: z.coerce.number(),
  })
  .partial();

export type ReportFilter = z.infer<typeof ReportFilterSchema>;
