import { Model, model, prop } from "mobx-keystone";

@model("web/CoalGrade")
export default class CoalGrade extends Model({
  id: prop<number>().withSetter(),
  name: prop<number>(0).withSetter(),
  minTm: prop<number>(0).withSetter(),
  maxTm: prop<number>(0).withSetter(),
  minAsh: prop<number>(0).withSetter(),
  maxAsh: prop<number>(0).withSetter(),
  minVm: prop<number>(0).withSetter(),
  maxVm: prop<number>(0).withSetter(),
  minFc: prop<number>(0).withSetter(),
  maxFc: prop<number>(0).withSetter(),
  minSul: prop<number>(0).withSetter(),
  maxSul: prop<number>(0).withSetter(),
  minGcv: prop<number>(0).withSetter(),
  maxGcv: prop<number>(0).withSetter(),
  minNcv: prop<number>(0).withSetter(),
  maxNcv: prop<number>(0).withSetter(),
}) {}
