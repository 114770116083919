import { forwardRef, InputHTMLAttributes } from "react";

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  title?: string;
  type?: string;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  divClassName?: string;
  className?: string;
  error?: string;
  hasError?: boolean;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      title = "",
      type = "text",
      placeholder = "",
      fullWidth = false,
      disabled = false,
      className = "",
      divClassName = "",
      error = "",
      hasError = false,
      ...props
    },
    ref,
  ) => {
    const fullWidthClass = fullWidth ? "w-full" : "";
    const errorClasses =
      error || hasError
        ? "border-red-500 focus:border-red-500 focus:ring-red-500"
        : "focus:border-primary focus:ring-primary border-gray-300";

    return (
      <div className={`flex flex-col gap-0.5 ${divClassName}`}>
        <p className="font-semibold">{title}</p>
        <input
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={`focus:ring-1.5 rounded-md px-3 py-1.5 text-lg shadow-sm ${fullWidthClass} ${errorClasses} ${className}`}
          onWheel={(e) => e.preventDefault()}
          ref={ref}
          {...props}
        />
        {error && <span className="px-3 text-red-700">{error}</span>}
      </div>
    );
  },
);

export default TextInput;
