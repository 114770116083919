import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import Button from "components/Button";
import { InputGroup, Label, Select } from "components/FormElements";
import PopperContainer from "components/PopperContainer";
import DateRangePicker from "components/datePicker/DateRangePicker";
import Supplier from "models/Supplier";
import { useStore } from "stores";
import { ReportFilter } from "types/report";
import { formatDateRange, naiveDate } from "utils/dates";

import ReportSupplierDropdown from "./ReportSupplierDropdown";

type Props = {
  initialData: ReportFilter;
  onSubmitFilter: (data: Partial<ReportFilter>) => void;
  clearedData: ReportFilter;
  isFetching?: boolean;
};

const ReportFilterSection = ({
  onSubmitFilter,
  initialData,
  clearedData,
  isFetching,
}: Props) => {
  const [supplierList, setSupplierList] = useState<Supplier[]>([]);
  const [hasSuppliers, setHasSuppliers] = useState<boolean>(true);
  const { register, handleSubmit, setValue, watch } = useForm<ReportFilter>({
    mode: "onChange",
    defaultValues: {
      loadedAt: initialData.loadedAt,
      certType: initialData.certType,
      grade: initialData.grade,
      suppliers: initialData.suppliers,
    },
  });
  const { authStore, certTypeStore, gradeStore, supplierStore } = useStore();
  const loadedAt = watch("loadedAt");
  const certType = watch("certType");
  const grade = watch("grade");
  const suppliers = watch("suppliers");

  useEffect(() => {
    if (certType) {
      const certTypeInt = parseInt(certType.toString(), 10);
      const certTypeObj = certTypeStore.types.get(certTypeInt);

      if (certTypeObj) setHasSuppliers(certTypeObj?.hasSupplier);
    }
    setValue("suppliers", undefined);
  }, [certType, certTypeStore.typeList]);

  const fetchSuppliers = useCallback(async () => {
    const { results: supplierData } = await supplierStore.fetchAllSuppliers(
      authStore.authHeader,
      grade,
    );

    setSupplierList(supplierData || []);
    setValue("suppliers", undefined);
  }, [grade]);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  const onSubmit = useCallback(
    (data: ReportFilter) => {
      onSubmitFilter(data);
    },
    [onSubmitFilter],
  );

  const onClear = useCallback(() => {
    setValue("loadedAt.lte", undefined);
    setValue("loadedAt.gte", undefined);
    setValue("certType", undefined);
    setValue("grade", undefined);
    setSupplierList([]);
    onSubmitFilter(clearedData);
  }, [onSubmitFilter]);

  const handleSupplierChange = (id: number | undefined) => {
    if (id === undefined) {
      setValue("suppliers", undefined);
      return;
    }
    const intId = parseInt(id.toString(), 10);
    let newSuppliers = [];

    if (suppliers && suppliers.length > 0) {
      if (suppliers.indexOf(intId) > -1) {
        newSuppliers = suppliers.filter((val) => val !== intId);
      } else {
        newSuppliers = [...suppliers, intId];
      }
    } else {
      newSuppliers = [intId];
    }
    setValue("suppliers", newSuppliers);
  };

  const isFormValid = useMemo(() => {
    if (!certType || !grade || !loadedAt || !loadedAt.gte || !loadedAt.lte) {
      return false;
    }
    return true;
  }, [loadedAt?.gte, loadedAt?.lte, certType, grade]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="my-2 flex gap-4">
        <InputGroup className="w-1/2">
          <Label className="text-base-main/70">Loading Date</Label>
          <PopperContainer
            buttonContent={
              <button
                type="button"
                className="flex w-full items-center justify-between rounded-lg border border-base-main bg-background px-3 py-2 text-left"
              >
                {loadedAt?.lte !== undefined && loadedAt?.gte !== undefined ? (
                  <span className="text-base-main">
                    {formatDateRange(
                      naiveDate(loadedAt.gte),
                      naiveDate(loadedAt.lte),
                    )}
                  </span>
                ) : (
                  <span className="text-base-main/[0.35]">Select</span>
                )}
                <span className="material-icons-outlined text-base-main/70">
                  calendar_month
                </span>
              </button>
            }
          >
            <DateRangePicker
              cancelOrSubmitToClose
              initialRange={
                initialData.loadedAt?.gte && initialData.loadedAt.lte
                  ? {
                      start: naiveDate(initialData.loadedAt?.gte),
                      end: naiveDate(initialData.loadedAt?.lte),
                    }
                  : undefined
              }
              onSubmit={(start, end) => {
                setValue("loadedAt.lte", format(end, "yyyy-MM-dd"));
                setValue("loadedAt.gte", format(start, "yyyy-MM-dd"));
              }}
              onCancel={() => {
                setValue("loadedAt.lte", undefined);
                setValue("loadedAt.gte", undefined);
              }}
            />
          </PopperContainer>
        </InputGroup>
        <InputGroup className="w-1/2">
          <Label className="text-base-main/70">Certificate Type</Label>
          <Select
            className="py-2"
            {...register("certType", {
              required: true,
            })}
            defaultValue=""
          >
            <option value="" disabled />
            {certTypeStore.typeList.map((cert) => (
              <option key={cert.id} value={cert.id}>
                {cert.name}
              </option>
            ))}
          </Select>
        </InputGroup>
      </div>
      <div className="my-2 flex gap-4">
        <InputGroup className="w-1/2">
          <Label className="text-base-main/70">Grade</Label>
          <Select
            className="py-2"
            {...register("grade", {
              required: true,
            })}
            defaultValue=""
          >
            <option value="" disabled />
            {gradeStore.gradeList.map((grade) => (
              <option key={grade.id} value={grade.id}>
                {grade.name}
              </option>
            ))}
          </Select>
        </InputGroup>
        {hasSuppliers && (
          <InputGroup className="w-1/2">
            <Label className="text-base-main/70">Supplier</Label>
            <PopperContainer
              popperProps={{ placement: "bottom-start" }}
              buttonContent={
                <button
                  type="button"
                  className="flex w-full items-center justify-between rounded-lg border border-base-main bg-background px-3 py-2 text-left"
                >
                  {suppliers && suppliers.length > 0 ? (
                    <span className="text-base-main/[0.86]">
                      {suppliers.length} suppliers selected
                    </span>
                  ) : (
                    <span className="text-base-main/[0.35]">All</span>
                  )}
                  <span className="material-icons-outlined text-base-main/70">
                    arrow_drop_down
                  </span>
                </button>
              }
            >
              <ReportSupplierDropdown
                supplierList={supplierList}
                selectedList={suppliers || []}
                onChange={handleSupplierChange}
              />
            </PopperContainer>
          </InputGroup>
        )}
      </div>
      <div className="flex gap-4 py-2 pb-4">
        <Button
          type="reset"
          disabled={isFetching}
          onClick={onClear}
          variant="outlined"
        >
          Clear
        </Button>
        <Button type="submit" disabled={!isFormValid} isLoading={isFetching}>
          Confirm
        </Button>
      </div>
    </form>
  );
};

export default observer(ReportFilterSection);
