import { computed } from "mobx";
import {
  Model,
  _async,
  _await,
  model,
  modelAction,
  ModelCreationData,
  modelFlow,
  prop,
} from "mobx-keystone";

import User from "models/User";
import { AuthHeader } from "types/auth";
import { API_ERROR_MESSAGES, APIError, ErrorMessage } from "types/error";
import fetchAPI from "utils/fetchAPI";

type LoginResponse = {
  key: string;
  user: ModelCreationData<User>;
};
@model("web/AuthStore")
export default class AuthStore extends Model({
  token: prop<string>("").withSetter(),
  isAuthenticated: prop<boolean>(false).withSetter(),
  isReady: prop<boolean>(false).withSetter(),
  currentUser: prop<User | undefined>().withSetter(),
  errors: prop<ErrorMessage[]>(() => []).withSetter(),
}) {
  @computed
  get authHeader(): AuthHeader {
    if (this.token.length > 0) {
      return { Authorization: `Bearer ${this.token}` };
    }
    return undefined;
  }

  @modelAction
  reset = () => {
    this.token = "";
    this.currentUser = undefined;
    this.isAuthenticated = false;
    this.isReady = false;
    this.errors = [];
  };

  @modelFlow
  load = _async(function* (this: AuthStore) {
    const response = yield* _await(this.checkAuthentication());

    return response;
  });

  @modelFlow
  checkAuthentication = _async(function* (this: AuthStore) {
    const url = "auth/check-auth/";
    let response: Response;

    try {
      response = yield* _await(
        fetchAPI(url, {
          headers: { ...this.authHeader },
          credentials: "include",
        }),
      );
    } catch {
      this.errors.push({
        source: "checkAuthentication",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      // throw new Error("Check authentication failed");
      return { details: "Check authentication failed", ok: false };
    }

    console.log("check auth response:", response);

    if (!response.ok) {
      this.reset();
      console.error("[DEBUG] Failed to authenticate with the credentials");
      return { details: "Check authentication failed", ok: false };
    }

    let currentUser: User;

    try {
      const data = (yield* _await(response.json())) as LoginResponse;

      console.log("check auth JSON response:", data);

      currentUser = new User({ ...data.user });
      // todo: there is no token here
      // this.setToken(data.key);
      this.setCurrentUser(currentUser);
    } catch (error) {
      console.error("[DEBUG] could not parse json");
      this.setToken("");
      this.setCurrentUser(undefined);
      this.setIsAuthenticated(false);
      this.setIsReady(true);
      return { details: error, ok: false };
    }
    this.setIsReady(true);
    this.setIsAuthenticated(true);
    return { details: "success", ok: true };
  });

  @modelFlow
  postLogout = _async(function* (this: AuthStore) {
    let response: Response;

    try {
      response = yield* _await(
        fetchAPI("auth/logout/", {
          method: "POST",
          credentials: "include",
        }),
      );
    } catch {
      this.errors.push({
        source: "postLogin",
        ...API_ERROR_MESSAGES[APIError.COULD_NOT_CONNECT],
      });
      return { details: "Logout failed failed", ok: false };
    }

    if (!response.ok) {
      console.error("[DEBUG] Failed to log out with the credentials");
      return { details: "Logout failed failed", ok: false };
    }

    this.setToken("");
    this.setCurrentUser(undefined);
    this.setIsAuthenticated(false);
    this.setIsReady(true);
    return { details: "Successfully logged out", ok: true };
  });

  @modelFlow
  postEmailLogin = _async(function* (this: AuthStore, email: string) {
    try {
      const response: Response = yield* _await(
        fetchAPI("auth/email-login/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }),
      );

      if (!response.ok) {
        const errorData = yield* _await(response.json());

        this.errors.push({
          source: "postEmailLogin",
          title: "Email login failed",
          message:
            errorData.detail?.message || "There was an error with logging in",
        });
        return {
          details: errorData.detail?.message || "Email login failed",
          ok: false,
        };
      }
      return { details: "Email login link sent", ok: true };
    } catch (error) {
      console.error("Email login failed:", error);
      this.errors.push({
        source: "postEmailLogin",
        title: "Email login failed",
        message: "There was an error with logging in",
      });
      return { details: error, ok: false };
    }
  });

  @modelFlow
  loginWithLink = _async(function* (this: AuthStore, token: string) {
    try {
      const response: Response = yield* _await(
        fetchAPI(`auth/link-login/?token=${token}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
      );

      if (!response.ok) {
        const errorData = yield* _await(response.json());

        throw new Error(errorData.detail || "Login with link failed");
      }

      const data = yield* _await(response.json());

      // const currentUser = new User(data.user);

      console.log("logged in response:", data);

      // // Response contains access, refresh tokens and user
      this.setToken(data.access);
      this.setCurrentUser(data.user);
      this.setIsAuthenticated(true);
      this.setIsReady(true);

      // console.log("current user:", this.currentUser);

      // return { details: "Login with link successful", ok: true };
      return this.checkAuthentication();
    } catch (error) {
      console.error("Login with link failed:", error);
      this.errors.push({
        source: "loginWithLink",
        title: "Email login failed",
        message: "There was an error with logging in",
      });
      return { details: error, ok: false };
    }
  });
}
