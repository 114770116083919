export type Values<T> = T[keyof T];

export type NestedPartial<T> = Partial<{
  [k in keyof T]: T[k] extends (infer U)[] ? U[] : Partial<T[k]>;
}>;

export type Ordering<T extends string> = T | `-${T}`;

export type Reversed<T extends Record<string, string>> = {
  [Value in T[keyof T]]: {
    [Key in keyof T]: T[Key] extends Value ? Key : never;
  }[keyof T];
};

export function getArrayOfKeys<T extends object>(obj: T) {
  return Object.keys(obj) as (keyof T)[];
}

export function getArrayOfValues<T extends object>(obj: T) {
  return Object.values(obj) as T[keyof T][];
}

export function reverseObject<T extends Record<string, string>>(obj: T) {
  const f: Record<string, string> = {};

  for (const [key, value] of Object.entries(obj)) {
    f[value] = key;
  }

  return f as Reversed<T>;
}

export type Singular<T> = T extends (infer U)[] ? U : T;
