type LoadingCircleProps = {
  size?: "sm" | "md" | "lg";
  borderSize?: number;
};

export default function LoadingCircle({ size = "sm" }: LoadingCircleProps) {
  return (
    <div className="flex items-center">
      {size === "sm" && (
        <span className="block h-4 w-4 animate-spin rounded-full border-2 border-t-primary-100" />
      )}
      {size === "md" && (
        <span className="block h-8 w-8 animate-spin rounded-full border-4 border-t-primary-100" />
      )}
      {size === "lg" && (
        <span className="block h-16 w-16 animate-spin rounded-full border-4 border-t-primary-100" />
      )}
    </div>
  );
}
