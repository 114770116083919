import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";

import { ReactComponent as CancelIcon } from "assets/close-icon.svg";
import { useStore } from "stores";
import { CertificateFilter } from "types/certificate";
import { formatDateRange, naiveDate } from "utils/dates";

type Props = {
  baseFilter: CertificateFilter;
  onUpdateFilter: (data: CertificateFilter) => void;
};

const CertificateFilterBreadcrumbs = ({
  baseFilter,
  onUpdateFilter,
}: Props) => {
  const { authStore, certTypeStore, supplierStore, userStore } = useStore();

  const fetchEntities = useCallback(async () => {
    if (baseFilter.certType) {
      await certTypeStore.fetchCertificateType(
        authStore.authHeader,
        baseFilter.certType,
      );
    }
    if (baseFilter.supplier) {
      await supplierStore.fetchSupplier(
        authStore.authHeader,
        baseFilter.supplier,
      );
    }
    if (baseFilter.createdBy) {
      await userStore.fetchUser(authStore.authHeader, baseFilter.createdBy);
    }
  }, []);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  return (
    <>
      {baseFilter.certType !== undefined && (
        <FilterChip
          fieldName="Type"
          value={certTypeStore.types.get(baseFilter.certType)?.name || ""}
          onClickClose={() => onUpdateFilter({ certType: undefined })}
        />
      )}
      {!!supplierStore && !!baseFilter.supplier && (
        <FilterChip
          fieldName="Supplier"
          value={supplierStore.suppliers.get(baseFilter.supplier)?.name || ""}
          onClickClose={() =>
            onUpdateFilter({
              supplier: undefined,
            })
          }
        />
      )}
      {!!userStore && !!baseFilter.createdBy && (
        <FilterChip
          fieldName="Uploader"
          value={userStore.users.get(baseFilter.createdBy)?.fullName || ""}
          onClickClose={() =>
            onUpdateFilter({
              createdBy: undefined,
            })
          }
        />
      )}
      {!!baseFilter.loadedAt?.gte && !!baseFilter.loadedAt.lte && (
        <FilterChip
          fieldName="Loaded At"
          value={formatDateRange(
            naiveDate(baseFilter.loadedAt.gte || ""),
            naiveDate(baseFilter.loadedAt.lte || ""),
          )}
          onClickClose={() =>
            onUpdateFilter({
              loadedAt: { gte: undefined, lte: undefined },
            })
          }
        />
      )}
      {!!baseFilter.createdAt?.gte && !!baseFilter.createdAt.lte && (
        <FilterChip
          fieldName="Uploaded At"
          value={formatDateRange(
            naiveDate(baseFilter.createdAt.gte || ""),
            naiveDate(baseFilter.createdAt.lte || ""),
          )}
          onClickClose={() =>
            onUpdateFilter({
              createdAt: { gte: undefined, lte: undefined },
            })
          }
        />
      )}
    </>
  );
};

export default observer(CertificateFilterBreadcrumbs);

type FilterChipProps = {
  fieldName: string;
  value: string;
  labelComponent?: React.ReactNode;
  onClickClose: () => void;
};

const FilterChip = ({
  onClickClose,
  fieldName,
  value,
  labelComponent,
}: FilterChipProps) => {
  return (
    <div className="flex items-center rounded-2xl px-[0.875rem] py-[0.25rem] outline outline-1 outline-base-main/[0.23]">
      <div className="flex items-center">
        {labelComponent ? (
          labelComponent
        ) : (
          <p className="text-xs leading-[166%] tracking-[0.4px] text-base-main/70">
            {fieldName}: <span className="font-bold">{value}</span>
          </p>
        )}
      </div>
      <button
        className="relative left-[11px] grid h-6 w-6 place-items-center opacity-[0.35] hover:opacity-[0.60]"
        onClick={() => onClickClose()}
      >
        <CancelIcon width={20} height={20} className="text-base-main" />
      </button>
    </div>
  );
};
