import { FunctionComponent, lazy, LazyExoticComponent, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import LoadingSuspense from "components/LoadingSuspense";
import ProtectedRoute from "components/ProtectedRoute";
import ToastProvider from "context/ToastProvider";
import Dashboard from "pages/Dashboard";
import Reports from "pages/Reports";

const Loadable =
  (Component: FunctionComponent | LazyExoticComponent<any>) => (props: any) =>
    (
      <Suspense fallback={<LoadingSuspense />}>
        <Component {...props} />
      </Suspense>
    );

const Root = Loadable(lazy(() => import("pages/Root")));
const Login = Loadable(lazy(() => import("pages/Login")));
const LoginLink = Loadable(lazy(() => import("pages/LoginLink")));
const Logout = Loadable(lazy(() => import("pages/Logout")));
const PageNotFound = Loadable(lazy(() => import("pages/404")));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ToastProvider>
        <ProtectedRoute>
          <Root />
        </ProtectedRoute>
      </ToastProvider>
    ),
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "reports",
        element: (
          <ProtectedRoute>
            <Reports />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: (
      <ToastProvider>
        <Login />
      </ToastProvider>
    ),
  },
  {
    path: "/login-link/:token",
    element: (
      <ToastProvider>
        <LoginLink />
      </ToastProvider>
    ),
  },
  { path: "/logout", element: <Logout /> },
  { path: "*", element: <PageNotFound /> },
]);

export default router;
